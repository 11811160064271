import {path, pipe, prop, find} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {
	formatUrlQuery,
	formatFetchableCalendarQuery,
	isSalesmanUser,
	formatCalendarResourceAddFormInput,
	formatCalendarResourceEditFormInput,
	isReadOnlyUser,
	formatCalendarResourceDateFormInput,
	formatEncounterAddFormInput,
	showRequestsForUser,
	formatReminderAddFormInput,
	checkTeamCalendarReminderVisibility,
	formatCalendarEventEditFormInput,
	formatCalendarEventAddFormInput,
} from './utils';
import {filterTeamTempAreas} from 'utils/areas';
import * as commonSelectors from 'modules/common/selectors';
import {calendarCreateCalendarEventForAnyUser, userCanCancelVisit} from 'utils/perms';
import createCallReminderSelectors from 'fragments/callReminder/selectors';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, prop('initialized'));

export const processing = pipe(root, prop('processing'));

export const calResLoading = pipe(root, prop('calResLoading'));

export const calendarQuery = pipe(root, prop('calendarQuery'));

export const urlQuery = createSelector([calendarQuery], calendarQuery => {
	return formatUrlQuery({calendarQuery});
});

export const calendarQueryFetchable = createSelector([calendarQuery], calendarQuery => {
	return formatFetchableCalendarQuery({calendarQuery});
});

export const teamId = createSelector(
	[calendarQuery],
	calendarQuery => calendarQuery.teamId,
);

export const weekSample = createSelector(
	[calendarQuery],
	calendarQuery => calendarQuery.weekSample,
);

export const onlyRequests = createSelector(
	[calendarQuery],
	calendarQuery => calendarQuery.teamId === 'requests',
);

export const hourItems = pipe(root, prop('hourItems'));

export const hourlessItems = pipe(root, prop('hourlessItems'));

export const itemsSource = pipe(root, prop('itemsSource'));

export const teams = pipe(root, prop('teams'));

export const activeOrganizationTeams = pipe(root, prop('activeOrganizationTeams'));

export const team = createSelector(
	[teamId, activeOrganizationTeams],
	(teamId, activeOrganizationTeams) =>
		find(team => team.id === teamId, activeOrganizationTeams),
);

export const itemsViewerOpen = pipe(root, prop('itemsViewerOpen'));

export const itemsCreatorOpen = pipe(root, prop('itemsCreatorOpen'));

export const slotSelection = pipe(root, prop('slotSelection'));

// PERM: ignore-visible-for-salesman
export const isSalesman = createSelector([commonSelectors.user], user =>
	isSalesmanUser(user),
);

// PERM: team-calendar-items-manage
export const isReadOnly = createSelector([commonSelectors.user], user =>
	isReadOnlyUser(user),
);

// PERM: team-calendar-show-requests
export const showRequests = createSelector([commonSelectors.user], user =>
	showRequestsForUser(user),
);

export const calendarResourceAddFormInitValues = createSelector([team], team =>
	formatCalendarResourceAddFormInput(team),
);

export const selectedItem = pipe(root, prop('selectedItem'));

export const previewableEncounter = pipe(root, prop('previewableEncounter'));

export const calendarResourceEditFormInitValues = createSelector(
	[selectedItem],
	selectedItem => formatCalendarResourceEditFormInput(selectedItem),
);

export const calendarEventEditFormInitValues = createSelector(
	[selectedItem],
	selectedItem => formatCalendarEventEditFormInput(selectedItem),
);

export const areasEditorOpen = pipe(root, prop('areasEditorOpen'));

export const dateEditorOpen = pipe(root, prop('dateEditorOpen'));

export const buildingCalendarResources = pipe(root, prop('buildingCalendarResources'));

export const buildingCalendarResourcesLoading = pipe(
	root,
	prop('buildingCalendarResourcesLoading'),
);

export const calendarBuildingModalOpen = pipe(root, prop('calendarBuildingModalOpen'));

export const building = pipe(root, prop('building'));

export const bonusItemReservationModalOpen = pipe(
	root,
	prop('bonusItemReservationModalOpen'),
);

export const bonusItemBeingReserved = pipe(root, prop('bonusItemBeingReserved'));

export const calendarResourceDateFormInitValues = createSelector(
	[bonusItemBeingReserved],
	bonusItemBeingReserved => formatCalendarResourceDateFormInput(bonusItemBeingReserved),
);

export const canCancelVisit = createSelector(
	[commonSelectors.user, selectedItem],
	(user, calendarResource) =>
		calendarResource && userCanCancelVisit({user, calendarResource}),
);

export const teamAreas = createSelector([team, slotSelection], (team, slotSelection) =>
	team ? filterTeamTempAreas(team.areas, slotSelection.date) : [],
);

export const leadBuilding = pipe(root, prop('leadBuilding'));

export const leadClient = pipe(root, prop('leadClient'));

export const lead = pipe(root, prop('lead'));

export const encounterAddFormInitValues = createSelector(
	[commonSelectors.user, leadBuilding, leadClient, lead],
	(user, building, clientId, lead) =>
		formatEncounterAddFormInput({user, building, clientId, lead}),
);

export const addBuildingModalOpen = pipe(root, prop('addBuildingModalOpen'));

export const mapModalOpen = pipe(root, prop('mapModalOpen'));

export const addBuildingFormInitValues = pipe(root, prop('addBuildingFormInitValues'));

export const buildingToAdd = pipe(root, prop('buildingToAdd'));

export const callReminderBuilding = pipe(root, prop('callReminderBuilding'));

const callReminderSelectors = createCallReminderSelectors({namespace});

export const {hourReminders} = callReminderSelectors;

export const callReminderAddFormInitValues = createSelector(
	[slotSelection],
	slotSelection => formatReminderAddFormInput(slotSelection),
);

export const userTeam = pipe(root, prop('userTeam'));

export const teamCalendarCallRemindersVisible = createSelector(
	[teamId, userTeam],
	(teamId, userTeam) =>
		checkTeamCalendarReminderVisibility({
			teamId,
			userTeam,
		}),
);

export const marketingLeadSources = pipe(root, prop('marketingLeadSources'));

export const products = pipe(root, prop('products'));

export const clientEditorOpen = pipe(root, prop('clientEditorOpen'));

export const lockingResources = pipe(root, prop('lockingResources'));

export const lockFrom = pipe(root, prop('lockFrom'));

export const lockModalOpen = pipe(root, prop('lockModalOpen'));

export const canCreateCalendarEventForAnyUser = createSelector(
	[commonSelectors.user],
	user => calendarCreateCalendarEventForAnyUser(user),
);

export const canEditCalendarEvent = createSelector(
	[commonSelectors.user, selectedItem],
	(user, item) => {
		return user && item && item?.model === 'CalendarEvent' && item.userId === user.id;
	},
);

export const calendarEventAddFormInitValues = createSelector(
	[slotSelection, commonSelectors.user, team],
	(slotSelection, user, team) =>
		formatCalendarEventAddFormInput(slotSelection, user, team),
);
