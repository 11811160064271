import {apiUrl} from 'constants/app';
import {logError} from 'io/errors';

export default (apiToken, apiKey) => {
	return new window.Pusher(apiKey, {
		cluster: 'eu',
		encrypted: true,
		authEndpoint: `${apiUrl}/pusherAuth`,
		auth: {headers: {Authorization: `Bearer ${apiToken}`}},
	});
};

/**
 * Create a topic string from a name and an accountId.
 * @param {string} name
 * @param {number} accountId
 * @returns {string}
 */
export const createTopic = (name, accountId) => {
	if (!name || !accountId) {
		logError(new Error('name and accountId are required for pusher topic creation'));
	}

	return `${name}@${accountId}`;
};
