import {path, pipe, prop, sortBy, uniqBy} from 'ramda';
import {createSelector} from 'reselect';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {
	formatUrlQuery,
	formatFetchableCalendarQuery,
	isSalesmanUser,
	formatCalendarResourceAddFormInput,
	formatCalendarEventAddFormInput,
	formatEncounterAddFormInput,
	formatCalendarResourceEditFormInput,
	getUserType,
	isProjectSalesUser,
	formatCalendarResourceDateFormInput,
	formatReminderAddFormInput,
	formatCalendarEventEditFormInput,
} from './utils';
import * as commonSelectors from 'modules/common/selectors';
import * as calendarSelectors from 'modules/calendarApp/common/selectors';
import {
	calendarCreateCalendarEventForAnyUser,
	isSubcontractorUser,
	userCanCancelVisit,
	userCanAddFreeTimes,
} from 'utils/perms';
import {filterTeamTempAreas} from 'utils/areas';
import createCallReminderSelectors from 'fragments/callReminder/selectors';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

export const initialized = pipe(root, prop('initialized'));

export const processing = pipe(root, prop('processing'));

export const userTeams = pipe(root, prop('userTeams'));

// PERM: userType
export const userType = createSelector([commonSelectors.user], user => getUserType(user));

export const team = createSelector([userTeams], userTeams =>
	userTeams.length ? userTeams[0] : null,
);

export const users = pipe(root, prop('users'));

export const selectableUsers = createSelector(
	[users, team, calendarSelectors.showTeamUsers],
	(users, team, showTeamUsers) =>
		team && showTeamUsers
			? sortBy(prop('lastName'), uniqBy(prop('id'), [...users, ...team.users]))
			: users,
);

export const calResLoading = pipe(root, prop('calResLoading'));

export const calendarQuery = pipe(root, prop('calendarQuery'));

export const salesmanId = pipe(calendarQuery, prop('salesmanId'));

export const urlQuery = createSelector([calendarQuery], calendarQuery => {
	return formatUrlQuery({calendarQuery});
});

export const calendarQueryFetchable = createSelector(
	[calendarQuery, salesmanId, commonSelectors.user, selectableUsers],
	(calendarQuery, salesmanId, currentUser, selectableUsers) => {
		return formatFetchableCalendarQuery({
			calendarQuery,
			salesmanId,
			currentUser,
			selectableUsers,
		});
	},
);

export const weekSample = createSelector(
	[calendarQuery],
	calendarQuery => calendarQuery.weekSample,
);

export const hourItems = pipe(root, prop('hourItems'));

export const hourlessItems = pipe(root, prop('hourlessItems'));

export const itemsSource = pipe(root, prop('itemsSource'));

export const itemsViewerOpen = pipe(root, prop('itemsViewerOpen'));

export const itemsCreatorOpen = pipe(root, prop('itemsCreatorOpen'));

export const slotSelection = pipe(root, prop('slotSelection'));

// PERM: ignore-visible-for-salesman
export const isSalesman = createSelector([commonSelectors.user], user =>
	isSalesmanUser(user),
);

export const isSubcontractor = createSelector([commonSelectors.user], user =>
	isSubcontractorUser(user),
);

export const calendarResourceAddFormInitValues = createSelector(
	[salesmanId, team],
	(salesmanId, team) => formatCalendarResourceAddFormInput(salesmanId, team),
);

export const calendarEventAddFormInitValues = createSelector(
	[slotSelection, commonSelectors.user, team],
	(slotSelection, user, team) =>
		formatCalendarEventAddFormInput(slotSelection, user, team),
);

export const callReminderAddFormInitValues = createSelector(
	[slotSelection],
	slotSelection => formatReminderAddFormInput(slotSelection),
);

// PERM: calendar-create-free-times
export const isProjectSales = createSelector([commonSelectors.user], user =>
	isProjectSalesUser(user),
);

export const canAddFreeTimes = createSelector([commonSelectors.user], user =>
	userCanAddFreeTimes(user),
);

export const selectedItem = pipe(root, prop('selectedItem'));

export const previewableEncounter = pipe(root, prop('previewableEncounter'));

export const encounterAddFormInitValues = createSelector(
	[commonSelectors.user, salesmanId, team],
	(user, salesmanId, team) => formatEncounterAddFormInput(user, salesmanId, team),
);

export const calendarResourceEditFormInitValues = createSelector(
	[selectedItem],
	selectedItem => formatCalendarResourceEditFormInput(selectedItem),
);

export const calendarEventEditFormInitValues = createSelector(
	[selectedItem],
	selectedItem => formatCalendarEventEditFormInput(selectedItem),
);

export const areasEditorOpen = pipe(root, prop('areasEditorOpen'));

export const dateEditorOpen = pipe(root, prop('dateEditorOpen'));

export const buildingCalendarResources = pipe(root, prop('buildingCalendarResources'));

export const callReminderBuilding = pipe(root, prop('callReminderBuilding'));

export const buildingCalendarResourcesLoading = pipe(
	root,
	prop('buildingCalendarResourcesLoading'),
);

export const bonusItemReservationModalOpen = pipe(
	root,
	prop('bonusItemReservationModalOpen'),
);

export const bonusItemBeingReserved = pipe(root, prop('bonusItemBeingReserved'));

export const calendarResourceDateFormInitValues = createSelector(
	[bonusItemBeingReserved],
	bonusItemBeingReserved => formatCalendarResourceDateFormInput(bonusItemBeingReserved),
);

export const canCancelVisit = createSelector(
	[commonSelectors.user, selectedItem],
	(user, calendarResource) =>
		calendarResource && userCanCancelVisit({user, calendarResource}),
);

export const canCreateCalendarEventForAnyUser = createSelector(
	[commonSelectors.user],
	user => calendarCreateCalendarEventForAnyUser(user),
);

export const canEditCalendarEvent = createSelector(
	[commonSelectors.user, selectedItem],
	(user, item) => {
		return user && item && item?.model === 'CalendarEvent' && item.userId === user.id;
	},
);

export const teamAreas = createSelector([team, slotSelection], (team, slotSelection) =>
	team ? filterTeamTempAreas(team.areas, slotSelection.date) : [],
);

const callReminderSelectors = createCallReminderSelectors({namespace});

export const {hourReminders} = callReminderSelectors;

export const marketingLeadSources = pipe(root, prop('marketingLeadSources'));

export const lockFrom = pipe(root, prop('lockFrom'));

export const clientEditorOpen = pipe(root, prop('clientEditorOpen'));
