import {contains} from 'ramda';
import {P} from 'utils/types';
import {effect} from 'utils/redux';
import namespace from './namespace';
import * as actions from './actions';
import {
	getCondo,
	postComment,
	deleteComment,
	updateComment,
	detachBuildingFromCondo,
	updateClient,
	postClient,
	deleteClient,
	saveBuilding,
	saveCondo as saveCondoIo,
	getCalendarResources,
	getUserTeams,
	postCalendarResource,
	postProjectCall,
	postFormFill,
	getFreeCalendarResources,
	get16100Clients,
	searchClientData,
	getBuilding,
	deleteCondo,
	attachBuildingToCondo,
	deleteManager,
	deleteHouseManager,
	skipBuilding,
	getNextBuilding,
	saveActivity as saveActivityIo,
	getProducts,
	getUsers,
	putCalendarResource,
	getAdditionalInfos,
} from './io';
import {catchNonFatalDefault} from 'io/errors';
import services from 'services';
import {decorateWithNotifications} from 'io/app';
import msgs from 'dicts/messages';
import * as confirmerActions from 'modules/confirmer/actions';
import * as selectors from './selectors';
import * as commonSelectors from 'modules/common/selectors';
import {change, blur, destroy as rfDestroy, reset} from 'redux-form';
import createDatePickEffects from 'fragments/calendarResourcePicker/effects';
import {bindToCalendarResourceReservationEvents} from 'fragments/calendarResourcePicker/effectHelpers';
import createBuildingModalEffects from 'fragments/buildingModalActions/effects';
import createCallReminderEffects from 'fragments/callReminder/effects';
import {initializeCallReminder as fetchCallReminders} from 'fragments/callReminder/effectHelpers';
import * as nActions from 'modules/notifications/actions';
import {medDur, longDur} from 'constants/notifications';
import {state as encounterStates} from 'dicts/encounters';
import * as mapActions from 'modules/projectSalesApp/mapPage/actions';
import {resolveObject} from 'utils/promises';
import {describeThrow, handledError} from 'utils/errors';
import importGoogleMaps from 'services/importGoogleMaps';
import {getReferrer, encodeQuery} from 'utils/url';
import {createTopic} from 'services/createPusher';

const creator = effect(namespace);

const history = services.get('history');

let intl = null;
services.waitFor('intl').then(x => (intl = x));

let pusher = null;
services.waitFor('pusher').then(x => (pusher = x));

const setupChannels = (getState, dispatch) => {
	pusher = services.get('pusher');
	const user = commonSelectors.user(getState());
	const calendarResourcesChannel = pusher.subscribe(
		createTopic('calendarResource', user.accountId),
	);

	bindToCalendarResourceReservationEvents({actions, calendarResourcesChannel, user})(
		getState,
		dispatch,
	);
};

const clearChannels = (getState, _dispatch) => {
	const user = commonSelectors.user(getState());
	pusher.unsubscribe(createTopic('calendarResource', user.accountId));
};

const fetchCondo =
	({notifyOpts = {}}) =>
	(getState, dispatch) => {
		const buildingId = selectors.condo(getState()).projectBuildingId;
		return decorateWithNotifications(
			{id: 'get-condo', ...notifyOpts},
			getCondo(buildingId),
		)(getState, dispatch).then(condo => {
			dispatch(actions._setCondo({condo}));
		});
	};

// this is a separate helper to increase readability. note that it doesn't return a promise since it may change the page
// NOTE: may crash if used after module destroyed
const doAfterEncounterOperation =
	(refreshCondo, refreshCalRes) => (getState, dispatch) => {
		dispatch(actions._setLoading(true));

		const seq = selectors.seq(getState());
		// we expect referrer to be up-to-date at this point
		const referrer = getReferrer(history.location.search);
		const activeCallPool = selectors.activeCallPool(getState());
		// if the callpool has a building which isnt a condominium and user skips the call, the app crashes because it doesn't find primaryBuilding
		// and the only times we use this buildingId is if refreshCalRes is true
		const buildingId = refreshCalRes && selectors.primaryBuilding(getState()).id;

		if (referrer === 'callPool' && activeCallPool) {
			dispatch(actions._setFetchingNextBuilding(true));

			decorateWithNotifications(
				{
					id: 'next-building',
					failureStyle: e => (e.causedByNoBuildingsAvailable ? 'info' : 'warning'),
					failureDuration: longDur,
				},
				getNextBuilding(activeCallPool.id),
			)(getState, dispatch)
				.catch(e => {
					dispatch(actions._setFetchingNextBuilding(false));

					if (!refreshCondo && !refreshCalRes) {
						// just stop loading if no need to refresh anything
						dispatch(actions._setLoading(false));
						throw e;
					}

					return (
						decorateWithNotifications(
							{id: 'get-call-data', failureStyle: 'warning'},
							Promise.all([
								refreshCalRes
									? getCalendarResources(buildingId).then(res => {
											dispatch(actions._setCalendarResources(res));
									  })
									: Promise.resolve(null),
								refreshCondo
									? fetchCondo({notifyOpts: {disableEverything: true}})(
											getState,
											dispatch,
									  )
									: Promise.resolve(null),
							]),
						)(getState, dispatch)
							// ignore any errors that happen when fetching the refreshed data - the previous error (when fetching the next building) is more relevant
							.catch(() => {
								dispatch(actions._setLoading(false));
							})
							.then(() => {
								dispatch(actions._setLoading(false));
								return Promise.reject(e);
							})
					);
				})
				.then(building => {
					if (selectors.seq(getState()) !== seq) {
						throw handledError('seq changed');
					}

					dispatch(actions._setFetchingNextBuilding(false));

					// btw: could perform the change here instantly instead of waiting on router + react to trigger changeBuilding. would require calling the stuff inside the handler and changeBuilding manually here, and result in a bit more complexity. not really worth it at this point since it would save < 100 ms, and letting the URL be the primary source works fine here because it doesn't refresh / isn't read often.
					history.push(
						`map${encodeQuery({
							buildingId: building.id,
							projectBuildingId: building.projectBuildingId,
							referrer: 'callPool',
						})}`,
					);
				})
				.catch(catchNonFatalDefault(getState, dispatch));
		} else {
			decorateWithNotifications(
				{id: 'get-call-data', failureStyle: 'warning'},
				Promise.all([
					refreshCalRes
						? getCalendarResources(buildingId).then(res => {
								dispatch(actions._setCalendarResources(res));
						  })
						: Promise.resolve(null),
					refreshCondo
						? fetchCondo({notifyOpts: {disableEverything: true}})(getState, dispatch)
						: Promise.resolve(null),
				]),
			)(getState, dispatch)
				.catch(e => {
					dispatch(actions._setLoading(false));
					throw e;
				})
				.then(() => dispatch(actions._setLoading(false)))
				.catch(catchNonFatalDefault(getState, dispatch));
		}
	};

export let initialize =
	({buildingId, isReinitialize = false}) =>
	(getState, dispatch) => {
		const user = commonSelectors.user(getState());
		const nId = 'init-project-sales-condo';
		const activeOrganizationId = commonSelectors.activeOrganizationId(getState());
		if (isReinitialize) destroyCallForm(getState, dispatch);
		if (!isReinitialize) setupChannels(getState, dispatch);

		dispatch(actions._setOpenedAt(new Date()));

		decorateWithNotifications(
			{id: nId, failureDuration: e => (e.causedByNoPermission ? longDur : medDur)},
			Promise.all([
				isReinitialize
					? Promise.resolve(null)
					: getUserTeams().then(teams => {
							dispatch(actions._setUserTeams(teams));
					  }),
				getCondo(buildingId)
					.then(condo => {
						dispatch(actions._setCondo({condo, buildingId}));

						return Promise.all([
							getCalendarResources(condo.projectBuildingId).then(resources => {
								dispatch(actions._setCalendarResources(resources));
								dispatch(actions._initialize());
							}),
							fetchCallReminders({actions, projectBuildingId: condo.projectBuildingId})(
								getState,
								dispatch,
							),
						]);
					})
					.catch(e => {
						if (e.causedByCondoNotFound) {
							// No condo found for buildingId, fetch the building
							dispatch(actions._condoNotFound());

							return Promise.all([
								getBuilding(buildingId).then(building => {
									dispatch(actions._setBuilding(building));
									dispatch(actions._initialize());
								}),
								fetchCallReminders({buildingId, actions})(getState, dispatch),
							]);
						} else {
							throw e;
						}
					}),
				getUsers().then(users => dispatch(actions._setUsers(users))),
				// note: getting calResources for the active building rather than the condo primary building. both should be the same though.
				getFreeCalendarResources(buildingId, user.id).then(cr =>
					dispatch(actions._setFreeCalRes(cr)),
				),
				getProducts(activeOrganizationId).then(p => dispatch(actions._setProducts(p))),
				getAdditionalInfos().then(ai => dispatch(actions._setAdditionalInfos(ai))),
				// import Google Maps for street view
				isReinitialize
					? Promise.resolve(null)
					: importGoogleMaps().catch(
							describeThrow(intl.formatMessage({id: 'Error loading map'})),
					  ),
			]),
		)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	};
initialize = creator('initialize', initialize, P.Object);

export let saveProductStates = buildingProductStates => (getState, dispatch) => {
	const buildingId = selectors.primaryBuilding(getState()).id;
	const building = {
		id: buildingId,
		// just use "call" here as the encounter type - the employee likely called the building if they made changes here.
		productEncounterType: 'call',
		productStates: buildingProductStates,
	};
	decorateWithNotifications(
		{
			id: 'post-product-states',
			failureStyle: 'error',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: msgs.saved}),
		},
		saveBuilding(building).then(building =>
			getCondo(building.id).then(condo => {
				dispatch(actions._setCondo({condo}));
			}),
		),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(() => {
			dispatch(actions._opOk());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
saveProductStates = creator('saveProductStates', saveProductStates, P.Array);

export let saveCondo =
	({form, initialValues}) =>
	(getState, dispatch) => {
		if (form.encounterState && form.encounterState !== initialValues.encounterState) {
			form.encounterDate = new Date();
			form.encounterType = 'none';
		}

		decorateWithNotifications(
			{
				id: 'save-condo',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Condominium saved'}),
			},
			saveCondoIo(form),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(condo => {
				dispatch(actions._saveCondo());
				dispatch(mapActions.updateMap());
				// we have to refetch the condo instead of using the one we get from response,
				// because for some reason backend doesn't return manager/houseManager if we created a new one
				return fetchCondo({notifyOpts: {}})(getState, dispatch);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
saveCondo = creator('saveCondo', saveCondo, P.Object);

export let saveActivity =
	({activity}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'save-activity',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: msgs.saved}),
			},
			saveActivityIo(activity),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(a => {
				dispatch(reset('activityForm'));
				dispatch(actions._saveActivity(a));

				if (activity.addCallReminder) {
					// refetch callReminders if added new
					fetchCallReminders({
						actions,
						projectBuildingId: selectors.projectBuildingId(getState()),
					})(getState, dispatch);
				}
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
saveActivity = creator('saveActivity', saveActivity);

export let createComment =
	({data, id}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'post-comment',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
			},
			id ? updateComment(data, id) : postComment(data),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(comment => {
				dispatch(actions._commentSaved(comment));
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
createComment = creator('createComment', createComment);

export let removeComment = id => (getState, dispatch) => {
	const onConfirm = () => {
		decorateWithNotifications(
			{
				id: 'delete-comment',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Comment deleted'}),
			},
			deleteComment(id),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._commentRemoved(id));
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete comment?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
removeComment = creator('removeComment', removeComment);

export let detachBuilding = id => (getState, dispatch) => {
	const onConfirm = () => {
		dispatch(actions._startOp());
		decorateWithNotifications(
			{
				id: 'detach-building',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Building detached'}),
			},
			detachBuildingFromCondo(id),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._buildingDetached(id));
				// disable reinitialize for the next render
				dispatch(actions.setDisableReinit(true));
				// center map on project building
				const projectBuilding = selectors
					.buildings(getState())
					.find(b => b.id === selectors.condo(getState()).projectBuildingId);
				dispatch(
					mapActions.openBuilding({
						refresh: true, // refresh layer source to update building's projectBuildingId
						buildingId: projectBuilding.id,
						projectBuildingId: projectBuilding.id,
						coords:
							projectBuilding.location && projectBuilding.location.coordinates
								? projectBuilding.location.coordinates[0]
								: null,
						transformCoords: true,
					}),
				);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Detach the building from the condominium?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
detachBuilding = creator('detachBuilding', detachBuilding);

export let saveClient =
	({client, buildingId}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'save-client',
				failureStyle: 'warning',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: msgs.saved}),
			},
			!buildingId ? updateClient(client) : postClient(client, buildingId),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(client => {
				dispatch(
					actions._updateClients({
						client: client.data,
						type: !buildingId ? 'update' : 'add',
					}),
				);

				// refetch condo if we updated manager/houseManager client
				const condo = selectors.condo(getState());
				if (condo) {
					const isManager =
						(condo.manager && condo.manager.id === client.data.id) ||
						(condo.houseManager && condo.houseManager.id === client.data.id);
					if (isManager) {
						// sometimes backend doesn't update condo's primary building clients fast enough
						// so _setCondo may overwrite clients with old data
						return fetchCondo({notifyOpts: {loading: msgs.loading}})(getState, dispatch);
					}
				}
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
saveClient = creator('saveClient', saveClient);

export let removeClient = id => (getState, dispatch) => {
	const onConfirm = () => {
		dispatch(actions._startOp());
		decorateWithNotifications(
			{
				id: 'delete-client',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Client deleted'}),
			},
			deleteClient(id),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._updateClients({id: id, type: 'remove'}));

				// refetch condo if we removed manager/houseManager client
				const condo = selectors.condo(getState());
				if (condo) {
					const isManager =
						(condo.manager && condo.manager.id === id) ||
						(condo.houseManager && condo.houseManager.id === id);
					if (isManager) {
						return fetchCondo({notifyOpts: {loading: msgs.loading}})(getState, dispatch);
					}
				}
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete client?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
removeClient = creator('removeClient', removeClient);

export let createProjectCall =
	({calendarResource, projectCall, formFill}) =>
	(getState, dispatch) => {
		const seq = selectors.seq(getState());

		const postIfNeeded = (item, relationName, postFunc) =>
			item ? postFunc(item).then(({id}) => ({[relationName]: id})) : Promise.resolve({});

		const activeCallPool = selectors.activeCallPool(getState());

		decorateWithNotifications(
			{
				id: 'save-projectCall',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: msgs.saved}),
			},
			resolveObject({
				rel1: postIfNeeded(calendarResource, 'calendarResourceId', postCalendarResource),
				rel2: postIfNeeded(formFill, 'formFillId', postFormFill),
			})
				.then(({rel1, rel2}) =>
					postProjectCall({
						...projectCall,
						...rel1,
						...rel2,
						callPoolId: activeCallPool ? activeCallPool.id : null,
					}),
				)
				.then(call => {
					destroyCallForm(getState, dispatch);

					dispatch(actions._opOk());

					dispatch(mapActions.updateMap());
				}),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				if (selectors.seq(getState()) === seq) {
					doAfterEncounterOperation(true, !!projectCall.appointment)(getState, dispatch);
				}
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
createProjectCall = creator('createProjectCall', createProjectCall);

export let createProjectVisit =
	({calendarResource}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'save-projectVisit',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: msgs.saved}),
			},
			postCalendarResource(calendarResource),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				getCalendarResources(calendarResource.buildingId).then(resources => {
					dispatch(actions._setCalendarResources(resources));
					dispatch(actions._opOk());
				});
				// update map and refetch condo to update it's state
				dispatch(mapActions.updateMap());
				return fetchCondo({notifyOpts: {}})(getState, dispatch);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
createProjectVisit = creator('createProjectVisit', createProjectVisit);

export let destroy = () => (getState, dispatch) => {
	clearChannels(getState, dispatch);
	destroyCallForm(getState, dispatch);
};
destroy = creator('destroy', destroy);

export let searchClients = buildings => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'search-clients',
			failureStyle: 'error',
		},
		Promise.all(
			buildings.map(b =>
				get16100Clients(b.id).catch(catchNonFatalDefault(getState, dispatch)),
			),
		),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(data => {
			const clients = data.map(d => (Array.isArray(d) ? d : null)).flat();
			dispatch(actions._set16100Clients(clients));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
searchClients = creator('searchClients', searchClients);

export let updateCalendarResource = calRes => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'update-calendar-resource',
			failureStyle: 'error',
			success: intl.formatMessage({id: 'Calendar entry saved'}),
		},
		putCalendarResource(calRes),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(calRes => {
			dispatch(actions._setCalendarResource(calRes));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
updateCalendarResource = creator('updateCalendarResource', updateCalendarResource);

export let getClientData = clientId => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'get-client-data',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.processing}),
		},
		searchClientData(clientId),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(data => {
			const clientType = selectors.clientType(getState());
			if (clientType === 'manager' || clientType === 'houseManager') {
				dispatch(actions._setNewManagerClients(data.data));
			} else {
				dispatch(actions._setNewClient(data.data));
			}
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};

export let removeCondo = condoId => (getState, dispatch) => {
	const onConfirm = () => {
		dispatch(actions._startOp());
		decorateWithNotifications(
			{
				id: 'remove-condo',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
			},
			deleteCondo(condoId),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._removeCondo());
				dispatch(mapActions.updateMap());
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({
				id: 'Delete condominium? Deletion will delete all the data in the condominium.',
			}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
removeCondo = creator('removeCondo', removeCondo);

const destroyCallForm = (getState, dispatch) => {
	setTimeout(() => {
		dispatch(rfDestroy('callClientForm'));
	});
};

export let destroyCallClientForm = () => (getState, dispatch) => {
	destroyCallForm(getState, dispatch);
};
destroyCallClientForm = creator('destroyCallClientForm', destroyCallClientForm);

export let attachBuilding = building => (getState, dispatch) => {
	if (building.projectBuildingId) {
		dispatch(
			nActions.warning({
				id: 'attach-building-condo-already-exists',
				message: intl.formatMessage({
					id: 'The building already belongs to a condominium - if you want to change its condominium, first remove it from existing condominium',
				}),
				duration: medDur,
			}),
		);
	} else {
		const onConfirm = () => {
			dispatch(actions._startOp());
			decorateWithNotifications(
				{
					id: 'attach-building',
					failureStyle: 'error',
					loading: intl.formatMessage({id: msgs.processing}),
				},
				attachBuildingToCondo(building.id, selectors.condo(getState()).id),
			)(getState, dispatch)
				.catch(e => {
					dispatch(actions._opFailed());
					throw e;
				})
				.then(condo => {
					dispatch(actions._attachBuilding());
					dispatch(actions._setCondo({condo, buildingId: building.id}));
					// disable reinitialize for the next render
					dispatch(actions.setDisableReinit(true));
					// show on map
					const _building = condo.buildings.find(b => b.id === building.id);
					dispatch(
						mapActions.openBuilding({
							refresh: true, // refresh layer source to update building's projectBuildingId
							buildingId: building.id,
							projectBuildingId: _building.projectBuildingId,
							coords:
								_building.location && _building.location.coordinates
									? _building.location.coordinates[0]
									: null,
							transformCoords: true,
						}),
					);
				})
				.catch(catchNonFatalDefault(getState, dispatch));
		};

		const mergableBuildingStates = ['notEncountered', 'takeContact'];

		if (contains(building.encounterState, mergableBuildingStates)) {
			dispatch(
				confirmerActions.show({
					message: intl.formatMessage(
						{
							id: 'Attach data from condominium "{condominium}" to building "{building}"?',
						},
						{
							condominium: selectors.condo(getState()).companyName,
							building: building.address,
						},
					),
					cancelText: intl.formatMessage({id: msgs.cancel}),
					onCancel: () => {},
					onOk: onConfirm,
				}),
			);
		} else {
			dispatch(
				confirmerActions.show({
					message: intl.formatMessage(
						{id: 'Attach the building even though its status is "{state}"?'},
						{state: intl.formatMessage({id: encounterStates[building.encounterState]})},
					),
					cancelText: intl.formatMessage({id: msgs.cancel}),
					onCancel: () => {},
					onOk: onConfirm,
				}),
			);
		}
	}
};
attachBuilding = creator('attachBuilding', attachBuilding, P.Object);

export let toggleAttachingBuilding = () => (getState, dispatch) => {
	const attaching = selectors.attachingBuilding(getState());
	if (attaching) {
		dispatch(
			nActions.info({
				id: 'toggle-attaching-building',
				message: intl.formatMessage({
					id: 'Select a building from the map to attach it to the condominium',
				}),
				duration: medDur,
			}),
		);
	}
};
toggleAttachingBuilding = creator('toggleAttachingBuilding', toggleAttachingBuilding);

export let removeManager = type => (getState, dispatch) => {
	const condoId = selectors.condo(getState()).id;
	const onConfirm = () => {
		dispatch(actions._startOp());
		decorateWithNotifications(
			{
				id: 'remove-manager',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Contact person deleted'}),
			},
			type === 'houseManager' ? deleteHouseManager(condoId) : deleteManager(condoId),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(condo => {
				dispatch(actions._opOk());
				dispatch(actions._setCondo({condo}));
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({
				id: 'Delete contact person?',
			}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
removeManager = creator('removeManager', removeManager);

export let skip = () => (getState, dispatch) => {
	const seq = selectors.seq(getState());

	decorateWithNotifications(
		{id: 'skip-b'},
		skipBuilding(selectors.building(getState()).id),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._setLoading(false));
			throw e;
		})
		.then(() => {
			if (selectors.seq(getState()) === seq) {
				doAfterEncounterOperation(false, false)(getState, dispatch);
			}
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
skip = creator('skip', skip);

const datePickEffects = createDatePickEffects({
	namespace,
	actions,
	selectResource: dateId => (getState, dispatch) => {
		dispatch(change('callClientForm', 'calendarResourceId', dateId));
		// this does maybe something important with redux-form, not sure what
		setTimeout(() => {
			dispatch(blur('callClientForm', 'calendarResourceId'));
		});
	},
});

export const {selectCalendarResource} = datePickEffects;

const buildingModalEffects = createBuildingModalEffects({
	namespace,
	actions,
});

export const {removeBuilding, saveBuildingData} = buildingModalEffects;

const callReminderEffects = createCallReminderEffects({
	namespace,
	actions,
});

export const {saveCallReminder, removeCallReminder} = callReminderEffects;
