import * as actions from './actions';
import {effect} from 'utils/redux';
import namespace from './namespace';
import * as selectors from './selectors';
import * as commonSelectors from 'modules/common/selectors';
import * as nActions from 'modules/notifications/actions';
import * as confirmerActions from 'modules/confirmer/actions';
import {medDur} from 'constants/notifications';
import {
	getCallPools,
	boostCallPoolArea as ioBoostCallPoolArea,
	getCallPoolWithAreaInfo,
	setAreaActivity as ioSetAreaActivity,
	deleteCallPoolArea,
	getAreas,
	postAddAreaToCallPool,
} from './io';
import services from 'services';
import {catchNonFatalDefault} from 'io/errors';
import {decorateWithNotifications} from 'io/app';
import msgs from 'dicts/messages';
import {createTopic} from 'services/createPusher';

const creator = effect(namespace);
let intl = null;
services.waitFor('intl').then(x => (intl = x));

let pusher = null;
services.waitFor('pusher').then(x => (pusher = x));

// Add later if needed
const setupChannels = (getState, dispatch) => {
	const user = commonSelectors.user(getState());

	pusher = services.get('pusher');
	const callPoolAreaChannel = pusher.subscribe(
		createTopic('AttachBuildingsToCallPool', user.accountId),
	);
	callPoolAreaChannel.bind('done', data => {
		const processingCallpoolId = selectors.processingCallpoolId(getState());
		const currentCallpool = selectors
			.callPools(getState())
			.find(callPool => callPool?.id && callPool.id === processingCallpoolId);
		const area = currentCallpool?.areas.find(
			area => area?.id && area.id === data?.areaId,
		);

		if (data?.callPoolId && data?.callPoolId === processingCallpoolId) {
			dispatch(
				nActions.success({
					id: `${processingCallpoolId}-boosting-area`,
					message: intl.formatMessage(
						{id: 'Boosting {area} for {callPool}'},
						{area: area?.title, callPool: currentCallpool?.title},
					),
					duration: medDur,
				}),
			);
		}
	});
};

const clearChannels = (getState, _dispatch) => {
	const user = commonSelectors.user(getState());
	pusher.unsubscribe(createTopic('AttachBuildingsToCallPool', user.accountId));
};

const fetchRestInitData = (getState, dispatch) =>
	decorateWithNotifications(
		{id: 'rest-init-data', failureStyle: 'warning'},
		getAreas().then(areas => dispatch(actions._setAllAreas(areas))),
	)(getState, dispatch);

export let initialize = () => (getState, dispatch) => {
	setupChannels(getState, dispatch);
	decorateWithNotifications(
		{id: 'get-callpools', failureStyle: 'warning'},
		getCallPools(),
	)(getState, dispatch)
		.then(data => {
			dispatch(actions._setCallPools(data));
			return fetchRestInitData(getState, dispatch);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let boostCallPoolArea =
	({areaId, callPoolId}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'boost-call-pool-area',
				failureStyle: 'warning',
				loading: intl.formatMessage({id: msgs.loading}),
			},
			ioBoostCallPoolArea({areaId, callPoolId}),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(data => {
				const currentCallpool = selectors
					.callPools(getState())
					.find(callPool => callPool?.id && callPool.id === callPoolId);
				const area = currentCallpool?.areas.find(
					area => areaId && area?.id && area.id === areaId,
				);
				dispatch(
					nActions.loading({
						id: `${callPoolId}-boosting-area`,
						message: intl.formatMessage(
							{id: 'Boosting {area} for {callPool}'},
							{area: area?.title, callPool: currentCallpool?.title},
						),
						duration: medDur,
					}),
				);
				dispatch(actions._setCallPoolArea([data, callPoolId]));
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
boostCallPoolArea = creator('boostCallPoolArea', boostCallPoolArea);

export let fetchCallPoolAreaInfo = cid => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'fetch-area-infos',
			failureStyle: 'warning',
		},
		getCallPoolWithAreaInfo(cid),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(data => dispatch(actions._setCallPoolWithAreaInfo(data)))
		.catch(catchNonFatalDefault(getState, dispatch));
};
fetchCallPoolAreaInfo = creator('fetchCallPoolAreaInfo', fetchCallPoolAreaInfo);

export let setAreaActivity =
	({areaId, callPoolId, active}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'callpool-area-activity',
				failureStyle: 'warning',
				success: intl.formatMessage({
					id: `Area changed to ${active ? 'active' : 'inactive'}`,
				}),
				loading: intl.formatMessage({id: msgs.loading}),
			},
			ioSetAreaActivity({areaId, callPoolId, active}),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(data => dispatch(actions._setCallPoolArea([data, callPoolId])))
			.catch(catchNonFatalDefault(getState, dispatch));
	};
setAreaActivity = creator('setAreaActivity', setAreaActivity);

export let removeCallPoolArea =
	({areaId, callPoolId}) =>
	(getState, dispatch) => {
		const onConfirm = () => {
			dispatch(actions._removingCallPoolArea(areaId));
			decorateWithNotifications(
				{
					id: 'callpool-area-activity',
					failureStyle: 'warning',
					success: intl.formatMessage({id: 'Area deleted'}),
					loading: intl.formatMessage({id: msgs.loading}),
				},
				deleteCallPoolArea({areaId, callPoolId}),
			)(getState, dispatch)
				.catch(e => {
					dispatch(actions._opFailed());
					throw e;
				})
				.then(_ => dispatch(actions._setCallPoolsAfterRemoval({callPoolId, areaId})))
				.catch(catchNonFatalDefault(getState, dispatch));
		};

		dispatch(
			confirmerActions.show({
				message: intl.formatMessage({
					id: `Delete area from callpool?`,
				}),
				cancelText: intl.formatMessage({id: msgs.cancel}),
				onCancel: () => {},
				onOk: onConfirm,
			}),
		);
	};
removeCallPoolArea = creator('removeCallPoolArea', removeCallPoolArea);

export let addAreaToCallPool =
	({callPoolId, areaData}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'add-area-to-callpool',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Area added to callpool'}),
			},
			postAddAreaToCallPool({
				callPoolId,
				areaData,
			}),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._addAreaToCallPoolFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._addAreaToCallPool());
				// refresh the callPool
				dispatch(actions._setCallPoolLoading(callPoolId));
				return decorateWithNotifications(
					{
						id: 'fetch-area-infos',
						failureStyle: 'warning',
					},
					getCallPoolWithAreaInfo(callPoolId).then(data =>
						dispatch(actions._setCallPoolWithAreaInfo(data)),
					),
				)(getState, dispatch);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
addAreaToCallPool = creator('addAreaToCallPool', addAreaToCallPool);

export let destroy = () => (getState, dispatch) => {
	clearChannels(getState, dispatch);
};
destroy = creator('destroy', destroy);
