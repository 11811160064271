import {
	curry,
	pipe,
	filter,
	pathOr,
	range,
	groupBy,
	assoc,
	map,
	ascend,
	contains,
	find,
	sortBy,
	partition,
	has,
} from 'ramda';
import {sort} from 'utils/arrays';
import {getDay, getWeekBoundaries, getUtcDay} from './time';
import * as calResDicts from 'dicts/calendarResources';
import {additionalInfos} from 'dicts/projectSales';
import {appointmentsHourRange} from 'constants/domain';
import {over} from 'utils/lenses';
import areaTitle from 'utils/areaTitle';
import services from 'services';
import {addHours, differenceInHours, format, parseISO} from 'date-fns';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const findRoleByTypes = (types, roles) =>
	find(r => contains(r.type, types), roles);

export const reserverRoleLabel = item => {
	const translationKey =
		calResDicts.reservationSourceAbbr?.[item.reservationSource] || null;

	return translationKey ? intl.formatMessage({id: translationKey}) : null;
};

const getCalendarEventableStartDate = item => {
	let d = null;

	if (item.dateFrom) {
		d = item.dateFrom;
	}
	if (item.callAt) {
		d = item.callAt;
	}

	if (item.startsAt) {
		d = item.startsAt;
	}
	return d ? new Date(d) : null;
};

export const isHourless = res => getCalendarEventableStartDate(res).getUTCHours() === 0;

export const isHourlesss = res => getCalendarEventableStartDate(res).getUTCHours() === 0;

export const type = item => {
	if (has('startsAt', item)) {
		// TODO: camel case
		return 'event';
	} else if (!(item.buildingId || item.clientId)) {
		return 'free';
	} else if (isHourless(item)) {
		return 'bonus';
	} else if (!item.salesmanVisitId) {
		return 'reserved';
	}
	return 'completed';
};

export const state = ({type, ...rest}) => {
	if (['free', 'callReminder', 'event'].includes(type)) {
		return null;
	}

	if (['reserved', 'bonus'].includes(type)) {
		return rest.salesmanId ? 'allocated' : 'unallocated';
	} else {
		return rest.salesmanVisit.state;
	}
};

export const projectState = ({type, reservation, latestActivity, ...rest}) =>
	// prettier-ignore
	type === 'reserved' && reservation?.additionalInfo ?
		additionalInfos[reservation.additionalInfo.id]
		: type === 'completed' ?
			latestActivity ? latestActivity.state : rest.salesmanVisit.state
			: null;

export const createResourceTitle = (
	intl,
	{
		type,
		virtual = false,
		allocated = false,
		cancelled = false,
		salesmanVisitState = null,
		seen = false,
		projectState = null,
		item = null,
		resolved = false, // used by calendar events
	},
) => {
	const base =
		type === 'event'
			? item?.calendarEventType?.title || intl.formatMessage({id: 'Event'})
			: intl.formatMessage({
					id:
						projectState && calResDicts.projectState[projectState]
							? calResDicts.projectState[projectState]
							: type === 'completed'
							? calResDicts.salesmanVisitState[salesmanVisitState]
							: type === 'reminder'
							? 'Reminder'
							: calResDicts.type[type],
			  });
	// prettier-ignore
	const reservedState =
		type === 'reserved' ? `, ${intl.formatMessage({ id: allocated ? 'allocated' : 'no salesperson' })}`
			: type === 'bonus' && allocated ? `, ${intl.formatMessage({ id: 'allocated' })}`
				: type === 'reminder' && seen ? `, ${intl.formatMessage({ id: 'seen' })}` : '';
	const extParts = [
		...(type === 'reserved' && cancelled ? [intl.formatMessage({id: 'cancelled'})] : []),
		...(virtual ? [intl.formatMessage({id: 'moved to'})] : []),
		...(type === 'event' ? [item?.title] : []),
		...(type === 'event' && resolved ? [intl.formatMessage({id: 'Resolved'})] : []),
	].filter(v => !!v);

	const ext = extParts.length ? ` (${extParts.join(', ')})` : '';
	return base + reservedState + ext;
};

export const resourceTitle = curry((intl, res, isProjectSales = false) =>
	createResourceTitle(intl, {
		type: res.type,
		virtual: !!res.virtual,
		allocated: !!res.salesmanId,
		cancelled: !!res.cancelled,
		salesmanVisitState: res.salesmanVisit && res.salesmanVisit.state,
		projectState: isProjectSales ? projectState(res) : null,
		item: res,
		resolved: res?.resolved || false,
	}),
);

// note: no practical need to sort by times, order will currently be correct as returned by api

export const getFree = filter(x => x.type === 'free');

export const getBonus = filter(x => x.type === 'bonus');

// scope allocated bonuses for given user
export const scopeAllocatedBonus = (items, userId) =>
	filter(
		x => (x.type === 'bonus' && x.state === 'allocated' ? x.salesmanId === userId : true),
		items,
	);

const reservedPrios = {unallocated: 1, allocated: 2};

export const getReserved = pipe(
	filter(x => x.type === 'reserved'),
	sort(ascend(i => reservedPrios[i.state] + (i.cancelled ? 2 : 0))),
);

const completedPrios = {
	notReached: 1,
	contacted: 2,
	offer: 3,
	deal: 4,
	cancelledDeal: 5,
	cancelledOffer: 6,
};

export const getCompleted = pipe(
	filter(x => x.type === 'completed'),
	sort(ascend(i => completedPrios[i.state])),
);

export const getCalendarEvents = items => {
	// TODO: change camelCase
	return items.filter(x => has('startsAt', x));
};

export const formatIntoWeekdaysAndHours = curry((weekSample, hourRange, items) => {
	const [weekStart, weekEnd] = getWeekBoundaries(weekSample);
	const hours = range(hourRange[0], hourRange[1] + 1);

	// filter out the items that are actually within the selected week
	const concreteItems = filter(
		i =>
			getCalendarEventableStartDate(i) >= weekStart &&
			getCalendarEventableStartDate(i) <= weekEnd,
		items,
	);

	// for items that have been recently moved, make virtual copies of them at their previous positions
	const virtualItems = pipe(
		filter(i => i.previousDateFrom >= weekStart && i.previousDateFrom <= weekEnd),
		map(assoc('virtual', true)),
	)(items);

	const dynamicGroup = items =>
		pipe(
			groupBy(i => getDay(getCalendarEventableStartDate(i))),
			map(groupBy(i => getCalendarEventableStartDate(i).getHours())),
		)(items);

	const groupByProp = (dateProp, items) =>
		pipe(
			groupBy(i => getDay(i[dateProp])),
			map(groupBy(i => i[dateProp].getHours())),
		)(items);

	const concreteGrouped = dynamicGroup(concreteItems);
	const virtualGrouped = groupByProp('previousDateFrom', virtualItems);

	return range(0, 7).map(iDay =>
		hours.reduce(
			(acc, hour) => {
				const getItems = pathOr([], [iDay, hour]);
				const getExtraItems = (items, hRange) => {
					let extraItems = [];
					hRange.forEach(h => {
						extraItems = [...extraItems, ...pathOr([], [iDay, h], items)];
					});
					return extraItems;
				};

				// we add items from 01:00 - 07:00 to 08:00 slot
				// and items from 21:00 - 23:00 to 20:00 slot
				// 00:00 slot is for bonus items
				// prettier-ignore
				const extraHoursRange =
					hour === appointmentsHourRange[0] ? range(1, appointmentsHourRange[0])
						: hour === appointmentsHourRange[1] ? range(appointmentsHourRange[1] + 1, 24)
							: [];

				const items = [
					...getItems(concreteGrouped),
					...getItems(virtualGrouped),
					...getExtraItems(concreteGrouped, extraHoursRange),
					...getExtraItems(virtualGrouped, extraHoursRange),
				];

				return {...acc, [hour]: items};
			},
			{},
			hours,
		),
	);
});

export const formatCallRemindersIntoWeekdaysAndHours = curry(
	(weekSample, hourRange, items) => {
		const [weekStart, weekEnd] = getWeekBoundaries(weekSample);
		const hours = range(hourRange[0], hourRange[1] + 1);

		// filter out the items that are actually within the selected week
		const concreteItems = filter(
			i => i.callAt >= weekStart && i.callAt <= weekEnd,
			items,
		);

		const group = (dateProp, items) =>
			pipe(
				groupBy(i => getDay(i[dateProp])),
				map(groupBy(i => i[dateProp].getHours())),
			)(items);

		const concreteGrouped = group('callAt', concreteItems);

		return range(0, 7).map(iDay =>
			hours.reduce(
				(acc, hour) => {
					const getItems = pathOr([], [iDay, hour]);
					const getExtraItems = (items, hRange) => {
						let extraItems = [];
						hRange.forEach(h => {
							extraItems = [...extraItems, ...pathOr([], [iDay, h], items)];
						});
						return extraItems;
					};

					// we add items from 00:00 - 07:00 to 08:00 slot
					// and items from 21:00 - 23:00 to 20:00 slot
					// prettier-ignore
					const extraHoursRange =
						hour === appointmentsHourRange[0] ? range(0, appointmentsHourRange[0])
							: hour === appointmentsHourRange[1] ? range(appointmentsHourRange[1] + 1, 24)
								: [];

					const items = [
						...getItems(concreteGrouped),
						...getExtraItems(concreteGrouped, extraHoursRange),
					];

					return {...acc, [hour]: items};
				},
				{},
				hours,
			),
		);
	},
);

const maybeSortByAreas = over(['areas'], area => sortBy(areaTitle, area || []));
export const normalizeItem = pipe(i => assoc('state', state(i), i), maybeSortByAreas);

export const groupHourlessItems = is => {
	const grouped = groupBy(i => getUtcDay(getCalendarEventableStartDate(i)), is);
	return map(iDay => grouped[iDay] || [], range(0, 7));
};

export const normalizeCalendarResources = (weekStart, _items) => {
	const items = _items.reduce((acc, cur) => {
		if (cur.model === 'CalendarEvent') {
			acc.push(...calendarEventToSlots(cur));
		} else {
			acc.push(cur);
		}
		return acc;
	}, []);

	const [hourlessItems, hourItems] = partition(isHourless, map(normalizeItem, items));
	return [
		groupHourlessItems(hourlessItems),
		formatIntoWeekdaysAndHours(weekStart, appointmentsHourRange, hourItems),
	];
};

export const normalizeItems = pipe(
	i => assoc('state', state(i), i),
	over(['areas'], sortBy(areaTitle)),
);

export const normalizeCallReminders = (weekStart, items = []) => {
	return [
		formatCallRemindersIntoWeekdaysAndHours(weekStart, appointmentsHourRange, items),
	];
};

export const isSalesAssignment = resource =>
	resource.reservation &&
	resource.reservation.encounter &&
	resource.reservation.encounter.state === 'salesAssignment';

export const projectStateLabel = state =>
	// prettier-ignore
	state === 1 ? '1'
		: state === 2 ? '2'
			: state === 3 ? 'I'
				: state === 'offer' ? 'T'
					: state === 'deal' ? 'K'
						: state === 'cancelledOffer' ? 'H'
							: null;

// some CR details (address, client) are hidden if:
// a.) user is salesman and CR isn't visible for salesman
// b.) CR's date is after team calendar's lockFrom and reservationSource is teleman
export const hideResourceDetails = ({
	resource = {},
	isSalesman = false,
	lockFrom,
	canViewLockedTeamCalendar = false,
}) => {
	if (isSalesman && !resource.visibleForSalesman) {
		return true;
	}

	if (
		lockFrom &&
		resource.dateFrom >= lockFrom &&
		resource.reservationSource === 'teleman' &&
		!canViewLockedTeamCalendar
	) {
		return true;
	}

	return false;
};

export const calendarEventToSlots = event => {
	const acc = [event];
	// Generate an array of hours between startsAt and endsAt by increasing current hour by 1 until it reaches endsAt
	// parse iso strings to date objects
	const startsAt = parseISO(event.startsAt);
	const endsAt = parseISO(event.endsAt);
	const duration = Math.abs(differenceInHours(startsAt, endsAt));
	// Generate an array of hours between startsAt and endsAt
	for (let i = 1; i < duration; i++) {
		// Generate new date by adding 1 hour to startsAt
		const hour = addHours(startsAt, i);
		// only add the slot if the hour is between 08:00 and 20:00 (finnish time +3)
		if (hour.getUTCHours() > 5 && hour.getUTCHours() < 18) {
			acc.push({
				...event,
				// format date to utc iso string, using date-fns
				startsAt: format(hour, "yyyy-MM-dd'T'HH:mm:ssxxx"),
				endsAt: format(addHours(hour, 1), "yyyy-MM-dd'T'HH:mm:ssxxx"),
			});
		}
	}
	return acc;
};
