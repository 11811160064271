import * as actions from './actions';
import {catchNonFatalDefault, logInfo} from 'io/errors';
import {P} from 'utils/types';
import namespace from './namespace';
import {effect} from 'utils/redux';
import {getReport, getPdf, getExcel, deleteReport} from './io';
import * as selectors from './selectors';
import * as commonSelectors from 'modules/common/selectors';
import {decorateWithNotifications} from 'io/app';
import services from 'services';
import msgs from 'dicts/messages';
import * as confirmerActions from 'modules/confirmer/actions';
import * as globalActions from 'modules/common/actions';
import {createTopic} from 'services/createPusher';

const creator = effect(namespace);

let intl = null;
services.waitFor('intl').then(x => (intl = x));
let pusher = null;
services.waitFor('pusher').then(x => (pusher = x));

const history = services.get('history');

const setupChannels = (getState, dispatch) => {
	const user = commonSelectors.user(getState());

	const pusher = services.get('pusher');
	const reports = pusher.subscribe(createTopic('report', user.accountId));

	reports.bind('completed', ({reportId}) => {
		const report = selectors.report(getState());
		// rule out some possible conflict cases
		if (!report || report.id !== reportId) {
			return;
		}
		getReport(reportId, {})
			.then(report => {
				dispatch(actions._setReport(report));
			})
			.catch(e => {
				logInfo(e);
			});
	});
};

const clearChannels = (getState, _dispatch) => {
	const user = commonSelectors.user(getState());
	pusher.unsubscribe(createTopic('report', user.accountId));
};

export let initialize = id => (getState, dispatch) => {
	dispatch(globalActions.setOrganizationChangeRedirect('/reports/reports'));

	setupChannels(getState, dispatch);

	decorateWithNotifications({id: 'get-report', failureStyle: 'error'}, getReport(id, {}))(
		getState,
		dispatch,
	)
		.then(report => {
			dispatch(actions._setReport(report));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize, P.Number);

export let fetchPdf = () => (getState, dispatch) => {
	const {id} = selectors.report(getState());
	const sort = selectors.sort(getState());

	decorateWithNotifications(
		{id: 'get-pdf', loading: intl.formatMessage({id: msgs.processing})},
		getPdf(id, {sort}),
	)(getState, dispatch)
		.then(pdf => {
			dispatch(actions._setPdf(pdf));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
fetchPdf = creator('fetchPdf', fetchPdf);

export let fetchExcel = () => (getState, dispatch) => {
	const {id, type} = selectors.report(getState());

	decorateWithNotifications(
		{
			id: 'get-excel',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.processing}),
		},
		getExcel({id, type}),
	)(getState, dispatch)
		.then(excel => {
			dispatch(actions._setExcel(excel));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
fetchExcel = creator('fetchExcel', fetchExcel);

export let removeReport = () => (getState, dispatch) => {
	const {id} = selectors.report(getState());

	const onConfirmed = () => {
		decorateWithNotifications(
			{
				id: 'delete-report',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Report deleted'}),
			},
			deleteReport(id),
		)(getState, dispatch)
			.then(() => {
				history.push('/reports/reports');
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete report?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirmed,
		}),
	);
};
removeReport = creator('removeReport', removeReport);

export let setSort = sort => (getState, dispatch) => {
	const {id} = selectors.report(getState());

	decorateWithNotifications(
		{
			id: 'get-report',
			loading: intl.formatMessage({id: msgs.processing}),
			failureStyle: 'error',
		},
		getReport(id, {sort}),
	)(getState, dispatch)
		.then(report => {
			dispatch(actions._setReport(report));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
setSort = creator('setSort', setSort);

export let destroy = () => (getState, dispatch) => {
	dispatch(globalActions.setOrganizationChangeRedirect(null));

	clearChannels(getState, dispatch);
};
destroy = creator('destroy', destroy);
