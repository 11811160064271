import {action} from 'utils/redux';
import namespace from './namespace';
import {P, Nullable} from 'utils/types';
import createReminderActions from 'fragments/callReminder/actions';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const updateCalendarQuery = creator('updateCalendarQuery', P.Object);

export const createCalendarResources = creator('createCalendarResources', P.Object);

export const removeCalendarResources = creator('removeCalendarResources', P.Array);

export const updateCalendarResource = creator('updateCalendarResource', P.Object);

export const toggleItemsViewer = creator('toggleItemsViewer', P.Bool);

export const toggleItemsCreator = creator('toggleItemsCreator', P.Bool);

export const setSlotSelection = creator('setSlotSelection', P.Object);

export const clearSlotSelection = creator('clearSlotSelection');

export const searchBuildings = creator('searchBuildings', P.Object);

export const searchUsers = creator('searchUsers', P.Object);

export const searchTeams = creator('searchTeams', P.Object);

export const createEncounter = creator('createEncounter', P.Object);

export const setSelectedItem = creator('setSelectedItem', Nullable(P.Object));

export const openEncounterPreview = creator('openEncounterPreview', P.Object);

export const closeEncounterPreview = creator('closeEncounterPreview');

export const toggleClientEditor = creator('toggleClientEditor');

export const setItemReservationCancellation = creator(
	'setItemReservationCancellation',
	P.Boolean,
);

export const saveCalendarResourceEditForm = creator(
	'saveCalendarResourceEditForm',
	P.Object,
);

export const saveCalendarEventEditForm = creator('saveCalendarEventEditForm', P.Object);

export const setAreasEditorOpen = creator('setAreasEditorOpen', P.Boolean);

export const setDateEditorOpen = creator('setDateEditorOpen', P.Boolean);

export const fetchBuildingCalendarResources = creator(
	'fetchBuildingCalendarResources',
	P.Number,
);

export const clearBuildingCalendarResources = creator('clearBuildingCalendarResources');

export const changeSalesman = creator('changeSalesman', P.Number);

export const openBonusItemReservationModal = creator(
	'openBonusItemReservationModal',
	P.Object,
);

export const closeBonusItemReservationModal = creator('closeBonusItemReservationModal');

export const reserveBonusItem = creator('reserveBonusItem', P.Object);

export const _setMarketingLeadSources = creator('_setMarketingLeadSources');

export const _setUserTeams = creator('_setUserTeams', P.Array);

export const _updateCalendarQuery = creator('_updateCalendarQuery', P.Object);

export const _setCalendarResources = creator('_setCalendarResources', P.Array);

export const _setCalendarEvents = creator('_setCalendarEvents', P.Array);

export const _addCalendarResources = creator('_addCalendarResources', P.Array);

export const _removeCalendarResources = creator('_removeCalendarResources', P.Array);

export const _updateCalendarResource = creator('_updateCalendarResource', P.Object);

export const _updateCalendarEvent = creator('_updateCalendarEvent', P.Object);

export const _setPreviewableEncounter = creator('_setPreviewableEncounter', P.Object);

export const _resetEditors = creator('_resetEditors');

export const _setBuildingCalendarResources = creator(
	'_setBuildingCalendarResources',
	P.Array,
);

export const _setBonusItemBeingReserved = creator('_setBonusItemBeingReserved', P.Object);

export const _setUsers = creator('_setUsers', P.Array);

export const _initialize = creator('_initialize');

export const _startOp = creator('_startOp');

export const _opOk = creator('_opOk');

export const _opFailed = creator('_opFailed');

export const _setLockFrom = creator('_setLockFrom');

const reminderActions = createReminderActions({namespace});

export const {
	createCallReminder,
	createCalendarCallReminder,
	updateCallReminder,
	removeCallReminder,
	toggleCallReminderEditor,
	setReminderSeen,
	_setCalendarCallReminderSeen,
	_setCallReminder,
	_setCallReminderLoading,
	_setCallReminders,
	_setCallRemindersLoading,
	_setCalendarCallReminder,
} = reminderActions;

export const onReminderClick = creator('onReminderClick');

export const setCallReminderBuilding = creator('setCallReminderBuilding');

export const saveClient = creator('saveClient', P.Object);

export const _clientSaved = creator('_clientSaved');

export const createCalendarEvent = creator('createCalendarEvent', P.Object);

export const _createCalendarEvent = creator('_createCalendarEvent', P.Object);

export const searchCalendarEventTypes = creator('searchCalendarEventTypes', P.Object);

export const deleteCalendarEvent = creator('deleteCalendarEvent', P.Object);

export const restoreCalendarEvent = creator('restoreCalendarEvent', P.Object);
