import {mergeLeft, pipe, defaultTo, assoc, equals} from 'ramda';
import {reset} from 'redux-form';
import {effect} from 'utils/redux';
import {P} from 'utils/types';
import {describeThrow} from 'utils/errors';
import services from 'services';
import msgs from 'dicts/messages';
import {transform} from 'ol/proj';
import {
	decorateWithNotifications,
	deleteProfinderList as _deleteProfinderList,
} from 'io/app';
import {catchNonFatalDefault} from 'io/errors';
import {replaceQuery, getQuery, pushQuery} from 'io/history';
import {geocodeGooglePlaceId} from 'io/geo';
import {getMapTileSource, addMarker} from 'io/maps';
import {
	buildingFocusZoom,
	maxZoom,
	areasMaxDetailZoom,
	buildingsMinZoom,
	groundwaterAreasMinZoom,
	propertyLinesMinZoom,
} from 'constants/maps';
import * as rootSelectors from 'modules/common/selectors';
import namespace from './namespace';
import * as actions from './actions';
import * as confirmerActions from 'modules/confirmer/actions';
import * as nActions from 'modules/notifications/actions';
import * as globalActions from 'modules/common/actions';
import {getTags as getAvailableTags} from 'modules/usersApp/tagsPage/io';
import * as selectors from './selectors';
import {parseUrlQuery, formatFetchableSelectionIds} from './utils';
import {createReferrerUrl, encodeQuery} from 'utils/url';
import {
	initMap,
	getBuildingsStyle,
	getAreasSource,
	getAreasStyle,
	getCallPoolsStyle,
	getCallPools,
	getActiveCallPool,
	deleteCallPoolArea,
	getAreaInfo,
	postAddAreaToCallPool,
	getIssues,
	postIssue,
	deleteIssue,
	getTeams,
	putCallPoolArea,
	getCallPoolsSource,
	getSalesmanVisits,
	boostCallPoolArea as ioBoostCallPoolArea,
	getCallPoolAreas,
	addDrawInteraction,
	postArea,
	deleteArea,
	getProducts,
	deleteCallPool,
	updateCallPool,
	createCallPool,
	getBuildings,
	getBuilding,
	getEncounters,
	getEncounter,
	getOrganizations,
	getCallPoolProfinderLists,
	fetchUsersByTeamId as fetchUsersByTeamIdIo,
} from './io';
import cache from './cache';
import {medDur, shortDur} from 'constants/notifications';
import * as Ls from 'io/localStorage';
import {TYPE_BUILDING} from 'modules/usersApp/tagsPage/constants';
import {createTopic} from 'services/createPusher';

const nsStr = namespace.join('.');

const creator = effect(namespace);

const history = services.get('history');

let intl = null;
services.waitFor('intl').then(x => (intl = x));

let pusher = null;
services.waitFor('pusher').then(x => (pusher = x));

const updateMapAreas = (getState, dispatch) => {
	const {areasLayer} = cache.read();
	if (areasLayer) {
		const currQuery = selectors.mapQuery(getState());
		const apiToken = rootSelectors.apiToken(getState());
		const org = rootSelectors.activeOrganization(getState());
		const {areaType, aggregateType, selectionIds, selectionType} = currQuery;

		getAreasSource({apiToken, areaType, organizationId: org.id}).then(x => {
			areasLayer.setSource(x);
			getAreasStyle({aggregateType, selectionIds, selectionType}).then(style =>
				areasLayer.setStyle(style),
			);
		});
	}
};

const updateMapCallPools = (getState, dispatch) => {
	const {callPoolsLayer} = cache.read();
	if (callPoolsLayer) {
		const apiToken = rootSelectors.apiToken(getState());
		const mapQuery = selectors.mapQuery(getState());
		const {selectionIds, selectionType, activeCallPoolId} = mapQuery;

		getCallPoolsSource({apiToken}).then(x => {
			callPoolsLayer.setSource(x);
			getCallPoolsStyle({activeCallPoolId, selectionIds, selectionType}).then(style =>
				callPoolsLayer.setStyle(style),
			);
		});
	}
};

const updateCurrentCallPoolProfinderLists = (getState, dispatch) => {
	const callPool = selectors.activeCallPool(getState());
	if (!callPool) {
		return Promise.resolve();
	}
	return getCallPoolProfinderLists(callPool.id).then(lists => {
		const currCallPool = selectors.activeCallPool(getState());
		if (currCallPool && currCallPool.id === callPool.id) {
			dispatch(actions._updateActiveCallPoolData({profinderCallPoolLists: lists}));
		}
	});
};

const setupChannels = (getState, dispatch) => {
	const user = rootSelectors.user(getState());
	pusher = services.get('pusher');

	const issueChannel = pusher.subscribe(createTopic('issue', user.accountId));
	const callPoolAreaChannel = pusher.subscribe(
		createTopic('AttachBuildingsToCallPool', user.accountId),
	);
	const areasChannel = pusher.subscribe(createTopic('areas', user.accountId));
	const profinderListChannel = pusher.subscribe(
		createTopic('ProfinderCallPoolList', user.accountId),
	);

	issueChannel.bind('completed', ({issueId, userId, csvUrl}) => {
		const user = rootSelectors.user(getState());
		if (userId === user.id) {
			dispatch(actions._setIssueCSV({issueId, csvUrl}));
		}
	});

	callPoolAreaChannel.bind('started', ({areaId, callPoolId}) => {
		const activeCallPool = selectors.activeCallPool(getState());
		if (activeCallPool && callPoolId === activeCallPool.id) {
			if (!areaId) {
				// all callPool areas started updating
				dispatch(actions._setAllCallableBuildingsLoading());
			} else {
				// single callPool area started updating
				dispatch(actions._setCallableBuildingsLoading(areaId));
			}
		}
	});

	callPoolAreaChannel.bind('done', ({areaId, callPoolId, countOfCallableBuildings}) => {
		const activeCallPool = selectors.activeCallPool(getState());
		if (activeCallPool && callPoolId === activeCallPool.id) {
			dispatch(actions._setCallableBuildings({areaId, countOfCallableBuildings}));
		}
	});

	areasChannel.bind('created', ({userId, areaId}) => {
		const user = rootSelectors.user(getState());
		if (userId === user.id) {
			dispatch(
				nActions.success({
					id: 'create-custom-area',
					message: intl.formatMessage({id: 'Area created'}),
					duration: shortDur,
				}),
			);
			updateMapAreas(getState, dispatch);
		}
	});

	profinderListChannel.bind('done', ({userId}) => {
		const user = rootSelectors.user(getState());

		if (userId !== user.id) {
			return;
		}
		updateCurrentCallPoolProfinderLists(getState, dispatch).then(() => {
			dispatch(actions._setCreatingProfinderList(false));
			dispatch(
				nActions.success({
					id: 'profinder-created',
					message: intl.formatMessage({id: 'Profinder filter added'}),
					duration: shortDur,
				}),
			);
		});
	});
};

const clearChannels = (getState, _dispatch) => {
	const user = rootSelectors.user(getState());
	pusher.unsubscribe(createTopic('issue', user.accountId));
	pusher.unsubscribe(createTopic('AttachBuildingsToCallPool', user.accountId));
	pusher.unsubscribe(createTopic('areas', user.accountId));
	pusher.unsubscribe(createTopic('ProfinderCallPoolList', user.accountId));
};

// just unconditionally clear activeCallPoolId when organization changes
const prepForOrgChange = (getState, dispatch) => {
	const currUrlQuery = selectors.urlQuery(getState());
	const orgRedirectUrl = `/maps${encodeQuery({...currUrlQuery, activeCallPoolId: null})}`;
	dispatch(globalActions.setOrganizationChangeRedirect(orgRedirectUrl));
};

const getActiveCallPoolData = callPoolId => (getState, dispatch) => {
	dispatch(actions._setActiveCallPoolLoading(true));
	return getActiveCallPool(callPoolId)
		.catch(e => {
			dispatch(actions._setActiveCallPoolLoading(false));
			throw e;
		})
		.then(callPool => {
			dispatch(actions._setActiveCallPool(callPool));
		});
};

const getSelectionData =
	({selectionType, selectionIds}) =>
	(getState, dispatch) => {
		if (selectionType === 'area' && selectionIds.length) {
			dispatch(actions._setSelectionLoading(true));
			const areaIds = formatFetchableSelectionIds(selectionIds);
			return Promise.all([
				getAreaInfo(areaIds).then(area => {
					dispatch(actions._setSelection(area));
				}),
				getIssues(areaIds).then(issues => {
					dispatch(actions._setIssues(issues));
				}),
			]).catch(e => {
				dispatch(actions._setSelectionLoading(false));
				throw e;
			});
		} else if (selectionType === 'building' && selectionIds.length) {
			dispatch(actions._setSelectionLoading(true));
			return Promise.all([
				getBuilding(selectionIds[0]).then(building => {
					dispatch(actions._setSelection(building));
				}),
				getEncounters(selectionIds[0]).then(encounters => {
					dispatch(actions._setEncounters(encounters));
				}),
			]).catch(e => {
				dispatch(actions._setSelectionLoading(false));
				throw e;
			});
		} else {
			dispatch(actions._setSelectionLoading(false));
			return Promise.resolve(null);
		}
	};

const _updateSelection = prevQuery => (getState, dispatch) => {
	replaceQuery(mergeLeft(selectors.urlQuery(getState())));

	prepForOrgChange(getState, dispatch);

	const mapQuery = selectors.mapQuery(getState());
	const {
		aggregateType,
		selectionIds,
		selectionType,
		activeCallPoolId,
		z,
		encounterState,
		minYear,
		maxYear,
	} = mapQuery;

	const hasSelection = selectionType && selectionIds.length;
	if (
		hasSelection &&
		(!equals(selectionIds, prevQuery.selectionIds) ||
			selectionType !== prevQuery.selectionType)
	) {
		decorateWithNotifications(
			{
				id: 'update-selection',
				failureStyle: 'error',
			},
			getSelectionData({selectionIds, selectionType})(getState, dispatch),
		)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	} else {
		dispatch(actions._setSelectionLoading(false));
	}

	// highlight selection on the map
	// or clear highlight if selection cleared
	if (
		selectionType === 'area' ||
		(prevQuery.selectionType === 'area' && selectionType !== 'area')
	) {
		const {areasLayer, callPoolsLayer} = cache.read();
		if (areasLayer) {
			getAreasStyle({aggregateType, selectionIds, selectionType})
				.then(style => areasLayer.setStyle(style))
				.catch(catchNonFatalDefault(getState, dispatch));
		}
		if (callPoolsLayer) {
			getCallPoolsStyle({activeCallPoolId, selectionIds, selectionType})
				.then(style => callPoolsLayer.setStyle(style))
				.catch(catchNonFatalDefault(getState, dispatch));
		}
	}
	if (
		selectionType === 'building' ||
		(prevQuery.selectionType === 'building' && selectionType !== 'building')
	) {
		const {buildingsLayer} = cache.read();
		if (buildingsLayer) {
			const org = rootSelectors.activeOrganization(getState());
			getBuildingsStyle({
				zoom: z,
				filters: {encounterState, minYear, maxYear},
				selectionIds,
				selectionType,
				manufacturingLimit: org.meta.manufacturingLimit,
			})
				.then(style => buildingsLayer.setStyle(style))
				.catch(catchNonFatalDefault(getState, dispatch));
		}
	}
};

const _clearDraw = (getState, dispatch) => {
	dispatch(actions._clearDraw());

	const {map, draw, drawSource} = cache.read();
	if (map && draw) {
		// clear radius tooltip
		const radiusTooltip = map.getOverlayById('radius-tooltip');
		const radiusTooltipEl = radiusTooltip.getElement();
		radiusTooltipEl.className = radiusTooltipEl.className.replace(
			/(^|\s)show($|\s)/,
			' ',
		);
		radiusTooltipEl.innerHTML = '';
		radiusTooltip.setPosition(null);

		map.removeInteraction(draw);
		cache.update(c => ({...c, draw: null}));
	}
	if (drawSource) {
		drawSource.clear();
	}
};

const _updateActiveCallPool = () => (getState, dispatch) => {
	replaceQuery(mergeLeft(selectors.urlQuery(getState())));

	const mapQuery = selectors.mapQuery(getState());
	const {activeCallPoolId, selectionIds, selectionType} = mapQuery;
	if (activeCallPoolId) {
		decorateWithNotifications(
			{
				id: 'update-active-callpool',
				failureStyle: 'error',
			},
			getActiveCallPoolData(activeCallPoolId)(getState, dispatch),
		)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	}

	// highlight active call pool on the map
	// or clear highlight if active call pool cleared
	const {callPoolsLayer} = cache.read();
	if (callPoolsLayer) {
		getCallPoolsStyle({activeCallPoolId, selectionIds, selectionType})
			.then(style => callPoolsLayer.setStyle(style))
			.catch(catchNonFatalDefault(getState, dispatch));
	}
};

const doInitMap = (getState, dispatch) => {
	const apiToken = rootSelectors.apiToken(getState());
	const org = rootSelectors.activeOrganization(getState());

	const mapQuery = selectors.mapQuery(getState());
	const {
		z,
		x,
		y,
		encounterState,
		minYear,
		maxYear,
		areaType,
		aggregateType,
		activeCallPoolId,
		selectionType,
		selectionIds,
		areasLayer,
		buildingsLayer,
		callPoolsLayer,
		groundwaterAreasLayer,
		propertyLinesLayer,
		mapSource,
	} = mapQuery;

	return initMap({
		apiToken,
		organizationId: org.id,
		initialZoom: z,
		initialCenter: [x, y],
		initialFilters: {encounterState, minYear, maxYear, areaType, aggregateType},
		activeCallPoolId,
		selectionType,
		selectionIds,
		manufacturingLimit: org.meta.manufacturingLimit,
		layersVisibility: {
			areasLayer,
			buildingsLayer,
			callPoolsLayer,
			groundwaterAreasLayer,
			propertyLinesLayer,
		},
		mapSourceProps: {sourceId: mapSource},
		onMapLocationChanged: location => dispatch(actions.updateMapQuery(location)),
		onBuildingClick: bid =>
			dispatch(actions.setSelection({selectionId: bid, selectionType: 'building'})),
		onAreaClick: (areaId, isMulti) =>
			isMulti
				? dispatch(
						actions.setAreaMultiSelection({
							selectionId: areaId,
							selectionType: 'area',
						}),
				  )
				: dispatch(actions.setSelection({selectionId: areaId, selectionType: 'area'})),
		getLayersVisibility: () => selectors.layersVisibility(getState()),
		getDrawing: () => selectors.drawing(getState()),
		getSelectionLoading: () => selectors.selectionLoading(getState()),
	}).then(resources => {
		cache.update(c => ({
			...c,
			map: resources.map,
			buildingsLayer: resources.buildingsLayer,
			areasLayer: resources.areasLayer,
			callPoolsLayer: resources.callPoolsLayer,
			drawSource: resources.drawSource,
			mapLayer: resources.mapLayer,
			markerLayer: resources.markerLayer,
			groundwaterAreasLayer: resources.groundwaterAreasLayer,
			propertyLinesLayer: resources.propertyLinesLayer,
		}));
	});
};

export let initialize = () => (getState, dispatch) => {
	setupChannels(getState, dispatch);

	prepForOrgChange(getState, dispatch);

	const storedQuery = Ls.getJson(`${nsStr}:query`) || {};
	// if visibility not present in url, use the values from localStorage
	const sourceQuery = {...storedQuery, ...getQuery()};
	const {mapQuery} = parseUrlQuery(sourceQuery);
	dispatch(actions._updateMapQuery(mapQuery));

	const {activeCallPoolId, selectionType, selectionIds} = mapQuery;

	decorateWithNotifications(
		{
			id: 'map-init',
			failureStyle: 'error',
		},
		Promise.all([
			doInitMap(getState, dispatch),
			getCallPools().then(callPools => {
				dispatch(actions._setCallPools(callPools));
			}),
			getTeams().then(teams => {
				dispatch(actions._setTeams(teams));
			}),
			getProducts().then(products => {
				dispatch(actions._setProducts(products));
			}),
			getOrganizations().then(organizations => {
				dispatch(actions._setOrganizations(organizations));
			}),
			getAvailableTags({
				type: TYPE_BUILDING,
				getAllTags: false,
			}).then(({data: tags}) => {
				dispatch(actions._setAvailableTags(tags));
			}),
			activeCallPoolId
				? getActiveCallPoolData(activeCallPoolId)(getState, dispatch)
				: Promise.resolve(),
			selectionType && selectionIds.length
				? getSelectionData({selectionType, selectionIds})(getState, dispatch)
				: Promise.resolve(),
			// TODO: clean up all smart data options when it's not needed anymore
			// getSmartDataOptions().then(opts => {
			// 	dispatch(actions._setSmartDataOptions(opts));
			// }),
		]),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let openPlacesSuggestion = placeId => (getState, dispatch) => {
	geocodeGooglePlaceId(placeId)
		.then(res => {
			const {lat, lng} = res.geometry.location;
			const coord = transform([lng(), lat()], 'EPSG:4326', 'EPSG:3857');
			const {map, markerLayer} = cache.read();

			const view = map.getView();
			view.animate({center: coord, zoom: buildingFocusZoom});

			// add marker at place's location
			if (markerLayer) {
				const source = markerLayer.getSource();
				source.clear();
				addMarker({id: placeId, coord, source});
			}
		})
		.catch(describeThrow(intl.formatMessage({id: 'Search failed'})))
		.catch(catchNonFatalDefault(getState, dispatch));
};
openPlacesSuggestion = creator('openPlacesSuggestion', openPlacesSuggestion, P.String);

export let updateMapQuery = prevQuery => (getState, dispatch) => {
	replaceQuery(mergeLeft(selectors.urlQuery(getState())));

	prepForOrgChange(getState, dispatch);

	// update layers based on new query
	const currQuery = selectors.mapQuery(getState());
	const {buildingsLayer, areasLayer} = cache.read();
	const {
		z,
		encounterState,
		minYear,
		maxYear,
		areaType,
		aggregateType,
		selectionIds,
		selectionType,
	} = currQuery;

	// areas layer
	if (areasLayer) {
		// get new areas source if areaType changed
		if (areaType !== prevQuery.areaType) {
			const apiToken = rootSelectors.apiToken(getState());
			const org = rootSelectors.activeOrganization(getState());
			getAreasSource({apiToken, areaType, organizationId: org.id})
				.then(x => areasLayer.setSource(x))
				.catch(catchNonFatalDefault(getState, dispatch));
		}
		// update layer style based on new query
		if (aggregateType !== prevQuery.aggregateType) {
			getAreasStyle({aggregateType, selectionIds, selectionType})
				.then(style => areasLayer.setStyle(style))
				.catch(catchNonFatalDefault(getState, dispatch));
		}
	}

	// buildings layer
	if (buildingsLayer) {
		// update layer style based on new query
		if (
			encounterState !== prevQuery.encounterState ||
			minYear !== prevQuery.minYear ||
			maxYear !== prevQuery.maxYear ||
			z !== prevQuery.z
		) {
			const org = rootSelectors.activeOrganization(getState());
			getBuildingsStyle({
				zoom: z,
				filters: {encounterState, minYear, maxYear},
				selectionIds,
				selectionType,
				manufacturingLimit: org.meta.manufacturingLimit,
			})
				.then(style => buildingsLayer.setStyle(style))
				.catch(catchNonFatalDefault(getState, dispatch));
		}
	}
};
updateMapQuery = creator('updateMapQuery', updateMapQuery);

export let setLayerVisibility =
	({layer, visible}) =>
	(getState, dispatch) => {
		pushQuery(mergeLeft(selectors.urlQuery(getState())));
		prepForOrgChange(getState, dispatch);
		const {z} = selectors.mapQuery(getState());
		const _cache = cache.read();
		if (_cache[layer]) {
			// don't set area or callPool layers visible if current zoom is less than areasMaxDetail zoom
			if (['areasLayer', 'callPoolsLayer'].includes(layer) && z <= areasMaxDetailZoom) {
				_cache[layer].setVisible(visible);
			} else if (layer === 'buildingsLayer' && z >= buildingsMinZoom) {
				_cache[layer].setVisible(visible);
			} else if (layer === 'groundwaterAreasLayer' && z >= groundwaterAreasMinZoom) {
				_cache[layer].setVisible(visible);
			} else if (layer === 'propertyLinesLayer' && z >= propertyLinesMinZoom) {
				_cache[layer].setVisible(visible);
			}
		}

		Ls.updateJson(`${nsStr}:query`, pipe(defaultTo({}), assoc(layer, visible)));
	};
setLayerVisibility = creator('setLayerVisibility', setLayerVisibility);

export let updateActiveCallPool = () => (getState, dispatch) => {
	_updateActiveCallPool()(getState, dispatch);
};
updateActiveCallPool = creator('updateActiveCallPool', updateActiveCallPool);

export let addAreasToCallPool =
	({areas, callPool}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'add-areas-to-callpool',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Areas added to callpool'}),
			},
			Promise.all(
				areas.map(a =>
					postAddAreaToCallPool(
						a.id,
						a.manufacturingYearStart,
						a.manufacturingYearEnd,
						a.excludeUsers,
						callPool.id,
					),
				),
			).then(areas => dispatch(actions._addAreasToCallPool(areas))),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._opOk());
				if (areas.length === 1) {
					dispatch(actions.setSelectionInfoTab('callPool'));
				}
				// add organization color and highlight on map
				updateMapCallPools(getState, dispatch);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
addAreasToCallPool = creator('addAreasToCallPool', addAreasToCallPool);

export let removeCallPoolArea =
	({callPoolId, areaId, areaTitle}) =>
	(getState, dispatch) => {
		const onConfirm = () => {
			dispatch(actions._startOp());
			decorateWithNotifications(
				{
					id: 'remove-callpool-area',
					failureStyle: 'error',
					loading: intl.formatMessage({id: msgs.processing}),
					success: intl.formatMessage({id: 'Area removed from callpool'}),
				},
				deleteCallPoolArea(callPoolId, areaId),
			)(getState, dispatch)
				.catch(e => {
					dispatch(actions._opFailed());
					throw e;
				})
				.then(() => {
					dispatch(actions._opOk());
					dispatch(actions._removeCallPoolArea(areaId));
					// remove organization color and highlight on map
					updateMapCallPools(getState, dispatch);
				})
				.catch(catchNonFatalDefault(getState, dispatch));
		};

		dispatch(
			confirmerActions.show({
				// eslint-disable-next-line
				message: intl.formatMessage(
					{id: 'Delete area "{area}" from callpool?'},
					{area: areaTitle},
				),
				cancelText: intl.formatMessage({id: msgs.cancel}),
				onCancel: () => {},
				onOk: onConfirm,
			}),
		);
	};
removeCallPoolArea = creator('removeCallPoolArea', removeCallPoolArea);

export let updateSelection = prevQuery => (getState, dispatch) => {
	_updateSelection(prevQuery)(getState, dispatch);
};
updateSelection = creator('updateSelection', updateSelection);

export let openMassEditor = areas => (getState, dispatch) => {
	const _areas = areas.map(a => ({id: a.id, title: a.title, subtitle: a.subtitle}));
	const areasQuery = JSON.stringify(_areas);
	history.push(`/buildings/buildings?areas=${areasQuery}`);
};
openMassEditor = creator('openMassEditor', openMassEditor);

export let createIssue = attributes => (getState, dispatch) => {
	const selection = selectors.selection(getState());
	const _issue = {
		...attributes,
		areas: selection.ids.map(id => ({id})),
	};
	decorateWithNotifications(
		{
			id: 'create-issue',
			failureStyle: 'error',
			loading: intl.formatMessage({id: 'Creating issue'}),
			success: intl.formatMessage({id: 'Issue created'}),
		},
		postIssue(_issue),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(issue => {
			dispatch(actions._createIssue(issue));
			dispatch(reset('issueCreateForm'));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
createIssue = creator('createIssue', createIssue);

export let removeIssue = issueId => (getState, dispatch) => {
	const onConfirm = () => {
		dispatch(actions._startOp());
		decorateWithNotifications(
			{
				id: 'remove-issue',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Issue deleted'}),
			},
			deleteIssue(issueId),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._opOk());
				dispatch(actions._removeIssue(issueId));
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Confirm the action'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
removeIssue = creator('removeIssue', removeIssue);

export let saveCallPoolArea = data => (getState, dispatch) => {
	dispatch(actions._startOp());
	const activeCallPool = selectors.activeCallPool(getState());
	const selection = selectors.selection(getState());

	decorateWithNotifications(
		{
			id: 'save-call-pool-area',
			failureStyle: 'error',
			loading: intl.formatMessage({id: 'Saving'}),
			success: intl.formatMessage({id: 'Area saved'}),
		},
		putCallPoolArea(activeCallPool.id, selection.ids[0], data),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(area => {
			dispatch(actions._opOk());
			dispatch(actions._saveCallPoolArea(area));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
saveCallPoolArea = creator('saveCallPoolArea', saveCallPoolArea);

export let getOffers =
	({areaId, _page}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'get-offers',
				failureStyle: 'error',
			},
			getSalesmanVisits(areaId, _page),
		)(getState, dispatch)
			.then(offers => {
				dispatch(actions._setOffers(offers));
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
getOffers = creator('getOffers', getOffers);

export let openOffer = offer => (getState, dispatch) => {
	// clear this on leave
	dispatch(globalActions.setOrganizationChangeRedirect(null));

	const referrerUrl = createReferrerUrl(history.location);
	history.push(
		`/maps/sales/${offer.calendarResource.id}${encodeQuery({
			referrer: 'maps',
			referrerUrl,
		})}`,
	);
};
openOffer = creator('openOffer', openOffer);

export let boostCallPoolArea =
	({areaId, callPoolId}) =>
	(getState, dispatch) => {
		dispatch(actions._startOp());
		decorateWithNotifications(
			{
				id: 'boost-call-pool-area',
				failureStyle: 'warning',
				success: intl.formatMessage({id: 'Rounds raised'}),
				loading: intl.formatMessage({id: msgs.loading}),
			},
			ioBoostCallPoolArea({areaId, callPoolId}),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(area => {
				dispatch(actions._opOk());
				dispatch(actions._setCallPoolArea(area));
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
boostCallPoolArea = creator('boostCallPoolArea', boostCallPoolArea);

export let updateActiveCallPoolAreas = callPoolId => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'update-call-pool-areas',
			failureStyle: 'warning',
		},
		getCallPoolAreas(callPoolId),
	)(getState, dispatch)
		.then(callPool => {
			const activeCallPool = selectors.activeCallPool(getState());
			if (activeCallPool && activeCallPool.id === callPool.id) {
				dispatch(actions._updateActiveCallPoolAreas(callPool.areas));
			}
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
updateActiveCallPoolAreas = creator(
	'updateActiveCallPoolAreas',
	updateActiveCallPoolAreas,
);

export let startDrawArea = type => (getState, dispatch) => {
	const {map, drawSource, callPoolsLayer} = cache.read();

	if (callPoolsLayer) {
		callPoolsLayer.setVisible(false);
		dispatch(actions._setLayerVisibility({layer: 'callPoolsLayer', visible: false}));
	}

	// clear old draw
	_clearDraw(getState, dispatch);
	dispatch(actions._setShowDrawMenu(true));

	// add new draw
	if (map && drawSource) {
		addDrawInteraction({
			map,
			drawSource,
			type,
			onDrawEnd: area => {
				dispatch(actions._endDrawArea(area));
				// to prevent selecting area when clicking the last dot
				setTimeout(() => {
					dispatch(actions._endDrawing());
				});
			},
		})
			.then(draw => cache.update(c => ({...c, draw})))
			.catch(catchNonFatalDefault(getState, dispatch));
	}
};
startDrawArea = creator('startDrawArea', startDrawArea);

export let clearDraw = () => (getState, dispatch) => {
	_clearDraw(getState, dispatch);
};
clearDraw = creator('clearDraw', clearDraw);

export let createCustomArea = data => (getState, dispatch) => {
	dispatch(actions._startOp());
	decorateWithNotifications(
		{
			id: 'create-custom-area',
			failureStyle: 'error',
			loading: intl.formatMessage({id: 'Saving'}),
			success: intl.formatMessage({id: 'The map updates when the area is created'}),
			successStyle: 'info',
		},
		postArea(data),
	)(getState, dispatch)
		.catch(e => {
			dispatch(actions._opFailed());
			throw e;
		})
		.then(area => {
			dispatch(actions._opOk());
			_clearDraw(getState, dispatch);
			// map areas are updated when pusher event arrives
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
createCustomArea = creator('createCustomArea', createCustomArea);

export let removeLastPoint = () => (getState, dispatch) => {
	const {draw} = cache.read();
	if (draw) draw.removeLastPoint();
};
removeLastPoint = creator('removeLastPoint', removeLastPoint);

export let removeCustomArea =
	({areaId, areaTitle}) =>
	(getState, dispatch) => {
		const onConfirm = () => {
			dispatch(actions._startOp());
			decorateWithNotifications(
				{
					id: 'remove-custom-area',
					failureStyle: 'error',
					loading: intl.formatMessage({id: msgs.processing}),
					success: intl.formatMessage({id: 'Area deleted'}),
				},
				deleteArea(areaId),
			)(getState, dispatch)
				.catch(e => {
					dispatch(actions._opFailed());
					throw e;
				})
				.then(() => {
					dispatch(actions._opOk());
					// clear selection
					const prevQuery = selectors.mapQuery(getState());
					dispatch(actions._clearSelection());
					_updateSelection(prevQuery)(getState, dispatch);
					// update map areas
					updateMapAreas(getState, dispatch);
				})
				.catch(catchNonFatalDefault(getState, dispatch));
		};

		dispatch(
			confirmerActions.show({
				// eslint-disable-next-line
				message: intl.formatMessage({id: 'Delete area "{area}"?'}, {area: areaTitle}),
				cancelText: intl.formatMessage({id: msgs.cancel}),
				onCancel: () => {},
				onOk: onConfirm,
			}),
		);
	};
removeCustomArea = creator('removeCustomArea', removeCustomArea);

export let removeCallPool = callPoolId => (getState, dispatch) => {
	const onConfirm = () => {
		dispatch(actions._startOp());
		decorateWithNotifications(
			{
				id: 'remove-call-pool',
				failureStyle: 'error',
				loading: intl.formatMessage({id: msgs.processing}),
				success: intl.formatMessage({id: 'Callpool deleted'}),
			},
			deleteCallPool(callPoolId),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(() => {
				dispatch(actions._opOk());
				dispatch(actions._removeCallPool(callPoolId));
				const activeCallPool = selectors.activeCallPool(getState());
				// if removed callPool was active, clear it
				if (activeCallPool && callPoolId === activeCallPool.id) {
					dispatch(actions._clearActiveCallPool());
					_updateActiveCallPool()(getState, dispatch);
				}
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Confirm the action'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
removeCallPool = creator('removeCallPool', removeCallPool);

export let saveCallPool =
	({id, data}) =>
	(getState, dispatch) => {
		dispatch(actions._startOp());
		decorateWithNotifications(
			{
				id: 'save-call-pool',
				failureStyle: 'error',
				success: intl.formatMessage({id: 'Callpool saved'}),
				loading: intl.formatMessage({id: 'Saving'}),
			},
			id ? updateCallPool(id, data) : createCallPool(data),
		)(getState, dispatch)
			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(callPool => {
				dispatch(actions._opOk());
				if (id) {
					// update active callPool and callPools list
					dispatch(actions._updateActiveCallPoolData(callPool));
					dispatch(actions._updateCallPool(callPool));
				} else {
					// set as active callPool and add to callPools list
					dispatch(actions._setActiveCallPoolQuery(callPool.id));
					dispatch(actions._addCallPool(callPool));
					_updateActiveCallPool()(getState, dispatch);
				}
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
saveCallPool = creator('saveCallPool', saveCallPool);

export let searchBuildings =
	({text, callback}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'search-buildings',
				failureStyle: 'warning',
			},
			getBuildings({_q: text, _limit: '20', include: 'clients'}),
		)(getState, dispatch)
			.then(buildings => {
				callback(buildings);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
searchBuildings = creator('searchBuildings', searchBuildings);

export let showBuildingOnMap =
	({coords, prevQuery}) =>
	(getState, dispatch) => {
		if (!coords || (coords[0] === 0 && coords[1] === 0)) {
			const message = intl.formatMessage({
				id: 'The building could not be found on the map',
			});
			dispatch(nActions.warning({id: 'building-not-found', message, duration: medDur}));
		} else {
			const {map} = cache.read();
			if (map) {
				// center to building's coords
				const view = map.getView();
				const _coords = transform(coords, 'EPSG:4326', 'EPSG:3857');
				view.animate({center: _coords, zoom: maxZoom});
			}
			_updateSelection(prevQuery)(getState, dispatch);
		}
	};
showBuildingOnMap = creator('showBuildingOnMap', showBuildingOnMap);

export let setMapSource = id => (getState, dispatch) => {
	pushQuery(mergeLeft(selectors.urlQuery(getState())));
	prepForOrgChange(getState, dispatch);

	const {mapLayer} = cache.read();
	getMapTileSource({user: rootSelectors.user(getState()), sourceId: id})
		.then(s => mapLayer.setSource(s))
		.catch(catchNonFatalDefault(getState, dispatch));
};
setMapSource = creator('setMapSource', setMapSource, P.String);

export let updateMapBuildings = () => (getState, dispatch) => {
	// this effect gets fired when switching back from backgrounded state, so do this here
	prepForOrgChange(getState, dispatch);

	const {map} = cache.read();

	// handle edge case where the map can get "unmounted" while the module is on background if the window size changes
	if (!map.isRendered()) {
		map.dispose();
		doInitMap(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
	}

	const {buildingsLayer} = cache.read();

	if (buildingsLayer) {
		buildingsLayer.getSource().refresh();
	}
};
updateMapBuildings = creator('updateMapBuildings', updateMapBuildings);

export let destroy = () => (getState, dispatch) => {
	dispatch(globalActions.setOrganizationChangeRedirect(null));
	clearChannels(getState, dispatch);
	cache.reset();
};
destroy = creator('destroy', destroy);

export let callBuilding = buildingId => (getState, dispatch) => {
	// clear this on leave
	dispatch(globalActions.setOrganizationChangeRedirect(null));

	const referrerUrl = createReferrerUrl(history.location);
	history.push(
		`/calls/buildings/${buildingId}${encodeQuery({
			referrer: 'maps',
			referrerUrl,
		})}`,
	);
};
callBuilding = creator('callBuilding', callBuilding);

export let editBuilding = buildingId => (getState, dispatch) => {
	// clear this on leave
	dispatch(globalActions.setOrganizationChangeRedirect(null));

	const referrerUrl = createReferrerUrl(history.location);
	history.push(
		`/maps/buildings/buildings/${buildingId}${encodeQuery({
			referrer: 'maps',
			referrerUrl,
		})}`,
	);
};
editBuilding = creator('editBuilding', editBuilding);

export let getEncounterData = id => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'get-encounter-data',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.processing}),
		},
		getEncounter(id),
	)(getState, dispatch)
		.then(encounter =>
			dispatch(
				actions._setActiveEncounterSource({
					source: encounter.source,
					sourceType: encounter.sourceType,
				}),
			),
		)
		.catch(catchNonFatalDefault(getState, dispatch));
};
getEncounterData = creator('getEncounterData', getEncounterData);

export let deleteProfinderList = id => (getState, dispatch) => {
	const onConfirm = () => {
		dispatch(actions._setProfinderListsProcessing(true));

		decorateWithNotifications(
			{
				id: 'rm-profinder',
				failureStyle: 'warning',
				loading: intl.formatMessage({id: msgs.processing}),
			},
			_deleteProfinderList({callPoolListId: id}),
		)(getState, dispatch)
			.then(() => updateCurrentCallPoolProfinderLists(getState, dispatch))
			.then(() => dispatch(actions._setProfinderListsProcessing(false)))
			.catch(catchNonFatalDefault(getState, dispatch))
			.catch(e => {
				dispatch(actions._setProfinderListsProcessing(false));
				throw e;
			});
	};

	dispatch(
		confirmerActions.show({
			message: intl.formatMessage({id: 'Delete filter?'}),
			cancelText: intl.formatMessage({id: msgs.cancel}),
			onCancel: () => {},
			onOk: onConfirm,
		}),
	);
};
deleteProfinderList = creator('deleteProfinderList', deleteProfinderList);

export let fetchUsersByTeamId = teamId => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'fetch-team-users',
			failureStyle: 'warning',
			loading: intl.formatMessage({id: msgs.processing}),
		},
		fetchUsersByTeamIdIo(teamId),
	)(getState, dispatch)
		.then(data =>
			dispatch(
				actions._fetchUsersByTeamId({
					id: teamId,
					users: data,
				}),
			),
		)
		.catch(catchNonFatalDefault(getState, dispatch));
};
fetchUsersByTeamId = creator('fetchUsersByTeamId', fetchUsersByTeamId);
