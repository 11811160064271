import {curry, pipe, map, pathOr, prop, mergeWith, add} from 'ramda';
import {over} from 'utils/lenses';
import {fullAddress as buildingAddress} from './buildings';
import {fullName as personName} from './people';
import {type as calendarResourceType} from './calendarResources';
import {zeroStats} from 'constants/stats';

// utils

const defaultTo = fallback => val => val != null ? val : fallback;

const tryUpdate = curry((path, f, obj) => {
	return pathOr(null, path, obj) ? over(path, f, obj) : obj;
});

const isObject = x => !!x && typeof x === 'object';

export const unwrapDatas = data =>
	// prettier-ignore
	Array.isArray(data) ?
		map(unwrapDatas, data)
	: isObject(data) ?
		Object.keys(data).reduce((acc, k) => {
			const v = data[k];
			const vNew = isObject(v) && Object.prototype.hasOwnProperty.call(v, "data")
				? unwrapDatas(v.data)
				: v;
			return {...acc, [k]: vNew};
		}, {})
	:
		data;

const parseDates = curry((dates, data) =>
	dates.reduce(
		(accData, date) => ({...accData, [date]: data[date] ? new Date(data[date]) : null}),
		data,
	),
);

const normalizeMeta = over(['meta'], defaultTo({}));

const genericDates = ['createdAt', 'updatedAt'];

const setBuildingAddress = b => ({...b, fullAddress: buildingAddress(b)});
const setPersonName = p => ({...p, fullName: personName(p)});

// helpers

// note: wrap the helpers in anonymous functions so that mutual references don't cause errors

const organizationExtra = x => normalizeMeta(x);

const buildingExtra = x =>
	pipe(
		parseDates(['encounterDate']),
		over(['otherAddressesFin'], defaultTo([])),
		over(['otherAddressesSwe'], defaultTo([])),
		setBuildingAddress,
	)(x);

const encounterSourceExtra = x =>
	pipe(
		parseDates(['createdAt']),
		over(['piimegaData'], defaultTo({})),
		tryUpdate(['calendarResource'], calendarResourceExtra),
	)(x);

const encounterExtra = x =>
	pipe(
		parseDates(['createdAt', 'date']),
		tryUpdate(['building'], buildingExtra),
		tryUpdate(['source'], encounterSourceExtra),
	)(x);

const calendarResourceExtra = x =>
	pipe(
		parseDates(['dateFrom', 'dateTo', 'previousDateFrom', 'previousDateTo']),
		tryUpdate(['client', 'building'], buildingExtra),
		tryUpdate(['reservation', 'encounter'], encounterExtra),
		tryUpdate(['reservation'], encounterSourceExtra),
		tryUpdate(['salesmanVisit'], salesmanVisitExtra),
		c => ({...c, type: calendarResourceType(c)}),
	)(x);

const salesmanVisitExtra = x => parseDates([...genericDates, 'dealAt'])(x);

const userExtra = x =>
	pipe(tryUpdate(['organizations'], map(organizationExtra)), setPersonName)(x);

const clientExtra = x => pipe(tryUpdate(['building'], buildingExtra), setPersonName)(x);

// normalizers

export const encounter = pipe(unwrapDatas, encounterExtra);

export const form = unwrapDatas;

export const formFill = unwrapDatas;

export const building = pipe(unwrapDatas, buildingExtra);

export const condo = pipe(unwrapDatas, tryUpdate(['buildings'], map(buildingExtra)));

export const callReminder = pipe(
	unwrapDatas,
	tryUpdate(['building'], buildingExtra),
	parseDates(['createdAt', 'callAt']),
);

export const callCampaign = pipe(
	unwrapDatas,
	tryUpdate(['query'], s => JSON.parse(s)),
);

export const ban = unwrapDatas;

export const client = pipe(unwrapDatas, clientExtra);

export const assignment = pipe(unwrapDatas, normalizeMeta);

export const team = unwrapDatas;

export const employee = unwrapDatas;

export const user = pipe(unwrapDatas, userExtra);

export const role = unwrapDatas;

export const organization = pipe(unwrapDatas, organizationExtra);

export const callPool = pipe(unwrapDatas, normalizeMeta);

export const calendarResource = pipe(unwrapDatas, calendarResourceExtra);

//export const calendarEvent = unwrapDatas;
export const calendarEvent = pipe(unwrapDatas, calendarResourceExtra);

export const calendarResourceCount = unwrapDatas;

export const salesmanVisit = pipe(unwrapDatas, salesmanVisitExtra);

export const report = unwrapDatas;

export const roles = unwrapDatas;

export const product = unwrapDatas;

export const notice = pipe(unwrapDatas, parseDates(genericDates));

export const buildingComment = unwrapDatas;

export const target = unwrapDatas;

export const reservation = unwrapDatas;

const userStatsTimespans = ['day', 'month', 'week'];

export const userStatsCollection = coll =>
	userStatsTimespans.reduce(
		(collAcc, timespan) =>
			over(
				[timespan],
				// the user may have zero earlier stats items or several if they've switched teams recently
				statsList =>
					statsList.length
						? // sum the stats items up if there are multiple
						  statsList.map(prop('data')).reduce(mergeWith(add), zeroStats)
						: zeroStats,
				collAcc,
			),
		coll,
	);

export const clients = unwrapDatas;

export const timeEntry = unwrapDatas;

export const enioCaller = unwrapDatas;

export const buildingIds = unwrapDatas;

export const lead = pipe(
	unwrapDatas,
	tryUpdate(['calendarResource'], calendarResourceExtra),
);

export const statistics = unwrapDatas;

export const salesAssignment = unwrapDatas;

export const survey = unwrapDatas;

export const surveyFill = unwrapDatas;

export const reference = unwrapDatas;

export const activity = unwrapDatas;

export const leadEvent = unwrapDatas;

export const removalRequest = unwrapDatas;

export const removalRequestComment = unwrapDatas;

export const log = unwrapDatas;

export const feedback = unwrapDatas;

export const adminEvent = unwrapDatas;

export const category = unwrapDatas;

export const file = unwrapDatas;

export const tag = unwrapDatas;

export const csvImport = unwrapDatas;

export const reasonMapping = unwrapDatas;

export const smsTemplate = unwrapDatas;

export const call = unwrapDatas;

export const visit = unwrapDatas;

export const dashboardTarget = unwrapDatas;

export const dashboardData = unwrapDatas;

export const smsLog = unwrapDatas;

export const calendarEventType = unwrapDatas;
